import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { titleStyle } from 'styles/global';
import DHILogo from 'assets/images/DHI_Logo_Blue.png';

export default function Title() {
  const classes = titleStyle();
  return (
    <Box className={classes.container}>
      <Grid container spacing={0} justify="space-between">
        <Grid item xs={10}>
          <Typography className={classes.titleMain}>Flood Metrics</Typography>
        </Grid>
        <Grid item>
          <img src={DHILogo} alt={'DHI Logo'} style={{ width: 36 }} />
        </Grid>
      </Grid>

      <Typography className={classes.subTitle}>
        Easy access to flood data
      </Typography>
    </Box>
  );
}
