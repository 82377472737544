import React from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { checkboxStyle } from 'styles/global';
import DHITheme from 'styles/theme';

const { primary } = DHITheme.palette;

export default function DatepickerCheckbox({
  calcPrice,
  expanded,
  productFrequency,
  setProductFrequency,
}) {
  const classes = checkboxStyle();
  const { yearRange, years } = productFrequency;

  const handleChange = async year => {
    let obj = productFrequency;
    if (obj.years && obj.years.includes(year) === false) obj.years.push(year);
    else obj.years.splice(obj.years.indexOf(year), 1);

    obj.price = await calcPrice(obj.years, obj.months);

    setProductFrequency({ ...obj });
  };

  return (
    <Grid container>
      {yearRange &&
        yearRange.map(year => (
          <Grid
            item
            xs={3}
            container
            key={year}
            onClick={() => handleChange(year)}
            style={{ cursor: 'pointer' }}
          >
            <Grid item xs={3}>
              <Checkbox
                checked={years ? years.includes(year) : false}
                checkedIcon={
                  <span className={([classes.icon], [classes.checkedIcon])} />
                }
                icon={<span className={[classes.icon]} />}
                style={{ width: 16, height: 16 }}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant="body2"
                style={{
                  paddingTop: 4,
                  color: `${primary.main}`,
                }}
              >
                {year}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}
