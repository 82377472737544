import React from 'react';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { Box } from '@material-ui/core';
import Map from 'components/Map';
import Sidebar from 'components/Sidebar';
import SidebarControl from 'components/SidebarControl';
import DialogWindow from 'components/Sidebar/Dialogs/DialogWindow';

import { draw } from 'components/Map/MapboxDraw';
import { sendContactInformation } from 'data';
import { polygonArea } from 'data/utils';
import { useWindowDimensions } from 'data/utils';
import { AppContext } from 'App';

export const HomeContext = React.createContext();

const Home = () => {
  const {
    actions: { resetProducts, setArea, setShowConfirmation },
    state: { area, openDrawer, productDEM, productExtent, productFrequency },
  } = React.useContext(AppContext);

  const [zoomBounds, setZoomBounds] = React.useState();

  const { width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [dialogue, setDialogue] = React.useState();

  const onStyleLoad = (map, loadEvent) => {
    map.addControl(draw);
    map.on('draw.create', createArea);
    map.on('draw.delete', deleteArea);
    map.on('draw.update', updateArea);

    setLoading(false);
  };

  const drawMode = shape => () => {
    // console.log('drawing', shape, draw);
    draw.deleteAll();
    switch (shape) {
      case 'polygon':
        enqueueSnackbar(`Draw ${shape} on map.`, { variant: 'info' });
        draw.changeMode('draw_polygon');
        break;
      case 'rectangle':
        enqueueSnackbar(`Draw ${shape} on map.`, { variant: 'info' });
        draw.changeMode('draw_rectangle');
        break;
      case 'clear':
        area &&
          enqueueSnackbar(`Previously selected area has been cleared.`, {
            variant: 'info',
          });
        resetProducts('all');
        break;
      default:
        draw.changeMode('draw_polygon');
        break;
    }
  };

  const handleSendMail = async details => {
    try {
      setLoading(true);
      const response = await sendContactInformation({
        baseUrl: 'mail',
        body: {
          ...details,
          application: 'floodmetrics',
          geometries: [area.coordinates],
          products: {
            flood_extent: await getProductDetails(productExtent),
            dem_floods: await getProductDetails(productDEM),
            flood_frequency: await getProductDetails(productFrequency),
          },
          request_date: formatISO(new Date(Date.now())),
        },
      });
      // await sendContactInformation({
      //   baseUrl: 'lead',
      //   body: {
      //     companyname: details.company,
      //     emailaddress1: details.email,
      //     firstname: details.name,
      //     lastname: '',
      //     dhi_commentfromweb: {
      //       message: details.message,
      //       geometries: [area.coordinates],
      //       products: {
      //         flood_extent: await getProductDetails(productExtent),
      //         dem_floods: await getProductDetails(productDEM),
      //         flood_frequency: await getProductDetails(productFrequency),
      //       },
      //     },
      //     dhi_marketingwebsourceurl: window.location.href,
      //     subject: 'Flood Metrics Lead',
      //   },
      // });
      if (!response) throw Error(`Request rejected`);
      enqueueSnackbar('Request sent! Thank you we will get back to you soon.', {
        variant: 'success',
      });
      setLoading(false);
      setShowConfirmation(true);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(
        'Request failed! Try again later or contact us via the support tab on the right.',
        {
          variant: 'error',
        }
      );
      Sentry.captureMessage(error);
      console.error(error);
    }
  };

  const getProductDetails = async product => {
    const { dates, price, resolutionRange, months, years } = product;

    if (price[1] === null) return;

    if (product === productExtent) {
      return {
        dates: dates.map(({ date, price, resolution }) => {
          let str = '';
          if (date && date !== null) {
            str += `Date: ${format(date, 'dd MMM yyyy')}, `;
            if (resolution[0].checked) str += `${resolution[0].title}`;
            if (resolution[0].checked && resolution[1].checked) str += ` and `;
            if (resolution[1].checked) str += `${resolution[1].title}`;
            if (price[0])
              str += ` = €${price[0].toLocaleString()} - €${price[1].toLocaleString()}`;
          }
          if (!resolution[0].checked && !resolution[1].checked) str = '';
          return str;
        }),
        price: price[0] + ' - ' + price[1],
      };
    } else if (product === productDEM) {
      return {
        resolutions: resolutionRange.map(
          ({ res, sum }) =>
            sum.min !== null &&
            sum.min !== 0 &&
            `${res} meter resolution = €${sum.min} - €${sum.max}`
        ),
        price: `Total price: ${price[0]} - ${price[1]}`,
      };
    } else if (product === productFrequency) {
      return {
        years: years.map(year => ` ${year}`),
        months: Object.keys(months).filter(i => months[i] && i),
        price: price[0] + ' - ' + price[1],
      };
    }
  };

  const createArea = e => {
    if (draw.getMode() === 'draw_rectangle') {
      const coord = [
        e.features[0].geometry.coordinates[0][0],
        e.features[0].geometry.coordinates[0][2],
      ];
      setZoomBounds(coord);
      draw.deleteAll();
    } else {
      let obj = {
        coordinates: e.features[0],
        sqm: polygonArea(e.features[0]),
      };
      setArea({ ...obj });
    }
  };

  const deleteArea = e => {
    // console.log('delete', e);
    // const data = draw.getAll();
    setArea({ coordinates: null, sqm: null });
  };

  const updateArea = e => {
    // console.log('update', e);
    let obj = {
      coordinates: e.features[0],
      sqm: polygonArea(e.features[0]),
    };
    setArea({ ...obj });
  };

  return (
    <HomeContext.Provider
      value={{
        actions: {
          drawMode,
          handleSendMail,
          setDialogue,
          setShowConfirmation,
        },
        state: {
          loading,
          width,
        },
      }}
    >
      <div style={{ width: '100vw', height: window.innerHeight - 1 }}>
        {dialogue && (
          <DialogWindow
            product={dialogue}
            handleClose={() => setDialogue()}
            open={true}
          />
        )}

        {width > 600 ? (
          <Box display="flex" height={1} width={1}>
            <Box flexGrow={1}>
              <Map
                onStyleLoad={onStyleLoad}
                mobile={false}
                zoomBounds={zoomBounds}
              />
            </Box>
            {openDrawer && (
              <Box
                width={360}
                style={{ borderLeft: '1px solid #DBE4E9' }}
                zIndex={1000}
              >
                <Sidebar />
              </Box>
            )}
            <Box
              width={37}
              style={{
                borderLeft: '1px solid #DBE4E9',
                boxSizing: 'border-box',
              }}
              zIndex={'tooltip'}
            >
              <SidebarControl mobile={false} />
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" height={1} width={1}>
            <Box width={1} flexGrow={1}>
              <Map
                onStyleLoad={onStyleLoad}
                mobile={true}
                zoomBounds={zoomBounds}
              />
            </Box>
            {openDrawer && (
              <Box height={'50%'} width={1} zIndex={1000}>
                <Sidebar />
              </Box>
            )}
            <Box
              height={36}
              style={{ borderTop: '1px solid #DBE4E9' }}
              zIndex={'tooltip'}
            >
              <SidebarControl mobile={true} />
            </Box>
          </Box>
        )}
      </div>
    </HomeContext.Provider>
  );
};

export default Home;
