import React from 'react';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FloodExtent from '../ProductComponents/FloodExtent';
// import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { DemoContext } from 'Demo';

import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';

const DemoFloodExtent = ({ match }) => {
  const {
    state: { demoProductsExtent, location },
    actions: { setLocation },
  } = React.useContext(DemoContext);

  const handleChange = i => {
    i === location ? setLocation() : setLocation(i);
  };

  return (
    <Box>
      <Typography variant="h6">Description of Flood extent</Typography>
      <Box px={2} pt={2} pb={0}>
        {defaultProducts[0].description}
      </Box>

      {demoProductsExtent.locations &&
        demoProductsExtent.locations.length > 0 && (
          <Typography variant="h6">Use cases for Flood extent</Typography>
        )}

      {demoProductsExtent.locations &&
        demoProductsExtent.locations.length > 0 &&
        demoProductsExtent.locations.map(({ date, title }, i) => (
          <Accordion
            key={i}
            expanded={location === i}
            onChange={() => handleChange(i)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ width: 16, color: '#0B4566' }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {/* <Link to={'/locationTitle'}> */}
              <Grid spacing={0} container>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontSize: 12 }}>
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              {/* </Link> */}
            </AccordionSummary>
            <AccordionDetails>
              <FloodExtent title={title} date={date} />
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

export default DemoFloodExtent;
