import React from 'react';
import classnames from 'classnames';
import { Box, Paper, Typography } from '@material-ui/core';
import './Datepicker.css';

export default function MonthPicker({
  calcPrice,
  productFrequency,
  setProductFrequency,
}) {
  const { months } = productFrequency;

  const handleChange = async month => {
    let obj = productFrequency;
    obj.months[month] = !obj.months[month];
    obj.price = await calcPrice(obj.years, obj.months);
    setProductFrequency({ ...obj });
  };

  return (
    <Box>
      <Box>
        <Typography variant="subtitle1" style={{ padding: '16px 0px 0px' }}>
          Include:
        </Typography>
      </Box>
      <Box>
        {months &&
          Object.keys(months).map(monthKey => {
            return (
              <Paper
                key={monthKey}
                className="datepicker__month"
                onClick={() => handleChange(monthKey)}
              >
                <div
                  className={classnames({
                    datepicker__checkbox: true,
                    datepicker__checkboxSelected: months[monthKey],
                  })}
                />
                <p
                  className={classnames({
                    datepicker__monthTitle: true,
                    datepicker__monthTitleSelected: months[monthKey],
                  })}
                >
                  {monthKey}
                </p>
              </Paper>
            );
          })}
      </Box>
    </Box>
  );
}
