import React from 'react';
import classnames from 'classnames';
import { ReactComponent as MapViewIcon } from '@mike/mike-shared-frontend/media/icons/MapView.svg';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { iconStyle } from 'styles/global';

import 'styles/searchbar.css';

const BaseLayerControl = ({ satelliteBase, setSatelliteBase }) => {
  const classes = iconStyle();

  return (
    <Grid
      container
      style={{ width: 36, boxShadow: 'rgba(0, 0, 0, 0.16) 4px 0px 4px' }}
    >
      <Grid container>
        <Tooltip placement="right" title={'Change base map'}>
          <IconButton
            className={classes.button}
            onClick={() => setSatelliteBase(!satelliteBase)}
          >
            <MapViewIcon
              className={classnames({
                [classes.icon]: true,
                [classes.activeIcon]: !satelliteBase,
              })}
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default BaseLayerControl;
