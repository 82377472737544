import React, { useEffect } from 'react';
import DEMcheckboxes from './DEMcheckboxes';
import { productStyle } from 'styles/global';
import { Box, Typography } from '@material-ui/core';
import { AppContext } from 'App';
import { HomeContext } from 'Home';
import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';

import 'nouislider/distribute/nouislider.css';
import 'styles/slider.css';

export default function DEM({ expanded }) {
  const {
    state: { area, productDEM },
    actions: { setProductDEM },
  } = React.useContext(AppContext);

  const {
    actions: { setDialogue },
  } = React.useContext(HomeContext);

  const DEMproduct = defaultProducts[1];
  const { priceCap, resolution, resolutionRange } = productDEM;
  const classes = productStyle();

  useEffect(() => {
    updatePrice(); // eslint-disable-next-line
  }, [area, resolutionRange]);

  const calcPrice = () => {
    const priceMin = resolutionRange
      .filter(res => res.checked)
      .reduce((acc, item) => acc + item.sum.min, 0);

    const priceMax = resolutionRange
      .filter(res => res.checked)
      .reduce((acc, item) => acc + item.sum.max, 0);

    if (priceMax >= priceCap) return [priceCap, priceCap];
    else return [priceMin, priceMax];
  };

  const updatePrice = () => {
    let obj = productDEM;
    obj.price = calcPrice(resolution);
    setProductDEM({ ...obj });
  };

  return (
    <Box>
      <Typography variant="body2" paragraph>
        Digital Elevation Models (DEM) are a basic requirement for flood
        inundation models. We provide updated and tailormade DEM’s, delivered in
        any ready-to-use GIS format, for any location in the world. Use the tick
        boxes below to select your preferred DEM product(s), depending on your
        requirements for the spatial resolution.{' '}
        <span
          onClick={() => setDialogue(DEMproduct)}
          className={classes.readMore}
        >
          Read more.
        </span>
      </Typography>
      <Typography variant="subtitle1">Choose resolution(s)</Typography>
      <Box pt={1} pr={1}>
        <DEMcheckboxes expanded={expanded} />
      </Box>

      {/* <Grid container spacing={0}>
        <Grid item xs={2}>
          <Typography
            onClick={() => resetProducts(productDEM.id)}
            className={classes.clearDays}
            style={{ cursor: 'pointer' }}
          >
            Clear data
          </Typography>
        </Grid>
      </Grid> */}
    </Box>
  );
}
