import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

const About = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={landingpage.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              About DHI GRAS
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              DHI GRAS is specialized in satellite image and data processing.
              DHI GRAS handles the entire data flow from the reception and
              processing of satellite images to the delivery of the requested
              final information products.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              The company was established in 2000 and has completed projects and
              delivered services in over 75 countries worldwide for a range of
              different clients. For more information about DHI GRAS please
              visit{' '}
              <a href="https://www.dhi-gras.com/">
                <span style={{ fontWeight: 700 }}>www.dhi-gras.com</span>
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default About;
