import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';

const Contact = () => {
  return (
    <Box>
      <Typography variant="body2" paragraph>
        With our global network of offices, we make sure you get the right
        answers to your local needs.
      </Typography>

      <Box py={0}>
        <Typography variant="body2">DHI A/S</Typography>
        <Typography variant="body2">
          Agern Alle 5, 2970 Hørsholm, Denmark
        </Typography>
        <Typography variant="body2">
          <Link href="mailto:gras@dhigroup.com">gras@dhigroup.com</Link>
        </Typography>
        <Typography variant="body2">+45 4516 9100</Typography>
        <Typography variant="body2">CVR no: 36466871</Typography>
      </Box>
    </Box>
  );
};

export default Contact;
