import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { landingpageStyle } from 'styles/landingpage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    background: theme.palette.primary.main,
  },
  text: {
    fontSize: 12,
    color: '#fff',
  },
}));

const About = () => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '5vh 0' }}>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text} style={{ fontWeight: 700 }}>
              DHI GRAS
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text}>gras@dhigroup.com</Typography>
            <Typography className={classes.text}>+45 4516 9100</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text}>Agern Alle 5,</Typography>
            <Typography className={classes.text}>2970 Hørsholm,</Typography>
            <Typography className={classes.text}>Denmark</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text}>CVR: 25621646</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default About;
