import React from 'react';

import GetQuoteButton from './GetQuoteButton';
import ProductListHeader from './ProductListHeader';
import TotalPrice from './TotalPrice';
import VolumeDisclaimerAccordion from './VolumeDisclaimers/VolumeDisclaimerAccordion';

import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { AppContext } from 'App';
import { Grid } from '@material-ui/core';

const priceStyle = {
  textAlign: 'right',
  fontSize: 12,
};

const ProductList = () => {
  const {
    state: { productExtent, productDEM, productFrequency },
  } = React.useContext(AppContext);

  const [expanded, setExpanded] = React.useState(false);
  const products = [productExtent, productDEM, productFrequency];

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const showPrice = price => {
    if (price) {
      if (price[0] === null || price[0] === 0) return '€0';
      else if (price[0] === price[1]) return `€${price[1].toLocaleString()}`;
      else
        return `€${price[0].toLocaleString()} - €${price[1].toLocaleString()}`;
    } else return '€0';
  };

  return (
    <Box>
      <ProductListHeader />

      {products &&
        products.map(({ component: Component, title, price, priceCap }, i) => (
          <Accordion
            key={i}
            expanded={expanded === 'panel' + i}
            onChange={handleChange('panel' + i)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ width: 16, color: '#0B4566' }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid spacing={0} container>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ fontSize: 12 }}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {price[0] >= priceCap || price[1] >= priceCap ? (
                    <VolumeDisclaimerAccordion />
                  ) : (
                    <Typography variant="h5" style={priceStyle}>
                      {showPrice(price)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Component expanded={expanded === 'panel' + i} />
            </AccordionDetails>
          </Accordion>
        ))}

      <TotalPrice />
      <GetQuoteButton />
    </Box>
  );
};

export default ProductList;
