import React from 'react';
import { Box, Button, Link, Paper, Typography } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

const linkStyle = {
  color: '#fff',
  fontSize: 14,
  fontWeight: 500,
};

const video = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const videoWrapper = {
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: '56.25%',
  border: '8px solid #fff',
  borderRadius: 4,
};

const Visit = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper
      className={landingpage.rootGrey}
      style={{
        padding: padding,
        textAlign: 'center',
      }}
    >
      <Box m="auto" p={2} className={landingpage.box}>
        <Typography variant={'h4'} className={landingpage.h4}>
          See for yourself
        </Typography>
        <Box p={1}></Box>
        <Box style={{ backgroundColor: '#fff' }}>
          <div style={videoWrapper}>
            <iframe
              src="https://www.youtube.com/embed/JjEZbFwP2dE"
              title="DHI flood metris"
              frameBorder="0"
              allow="fullscreen"
              allowFullScreen
              style={video}
            />
          </div>
        </Box>
        <Box p={2}></Box>
        <Button variant="contained" color="secondary" target="_blank">
          <Link href="/" style={linkStyle}>
            Visit Flood Metrics
          </Link>
        </Button>
      </Box>
    </Paper>
  );
};

export default Visit;
