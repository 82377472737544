import React from 'react';
import VolumeDisclaimerTotalPrice from '../VolumeDisclaimers/VolumeDisclaimerTotalPrice';
import { Grid, Typography } from '@material-ui/core';
import { productStyle } from 'styles/global';
import { AppContext } from 'App';

const TotalPrice = () => {
  const {
    state: { sum },
  } = React.useContext(AppContext);

  const classes = productStyle();
  const hardCodedPriceCap = 75000;

  const showSum = () => {
    if (sum) {
      if (sum[0] === null || sum[0] === 0) return '€0';
      else if (sum[0] >= hardCodedPriceCap)
        return `€ ${sum[1].toLocaleString()} +`;
      else if (sum[0] === sum[1]) return '€' + sum[1].toLocaleString();
      else
        return '€' + sum[0].toLocaleString() + ' - €' + sum[1].toLocaleString();
    } else return '€0';
  };

  return (
    <Grid
      container
      spacing={0}
      className={classes.totalPrice}
      style={{ padding: '8px 16px' }}
    >
      <Grid item xs={6}>
        <Typography className={classes.regionTitleMain}>
          Total estimated price
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {sum[0] >= hardCodedPriceCap ? (
          <VolumeDisclaimerTotalPrice />
        ) : (
          <Typography
            className={classes.regionTitleMain}
            style={{ textAlign: 'right' }}
          >
            {showSum()}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TotalPrice;
