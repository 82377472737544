import React from 'react';
import Loader from 'components/Loader';
import ReactMapboxGl from 'react-mapbox-gl';
import DHIMapControl from './DHIMapControl';
import { HomeContext } from 'Home';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const { token: accessToken, styles } = require('config.json');
const DEFAULT_BOUNDS = [
  [147, 76],
  [-120, -42],
];

const Mapbox = ReactMapboxGl({
  accessToken,
  minZoom: 2,
  maxZoom: 15,
});

const Map = ({ mobile, onStyleLoad, zoomBounds }) => {
  const {
    state: { loading },
  } = React.useContext(HomeContext);

  const [bounds, setBounds] = React.useState(DEFAULT_BOUNDS);
  const [satelliteBase, setSatelliteBase] = React.useState(false);
  const [zoom, setZoom] = React.useState([8]);

  React.useEffect(() => {
    zoomBounds && setBounds(zoomBounds);
  }, [zoomBounds]);

  const resetView = () => setBounds([...DEFAULT_BOUNDS]);

  // const [loading, setLoading] = React.useState(true);

  return (
    <>
      <Loader isLoading={loading} isMajorLoading={loading} />
      <Mapbox
        className="mapboxgl-map"
        containerStyle={{ height: '100%', width: '100%' }}
        fitBounds={bounds}
        onStyleLoad={onStyleLoad}
        onZoomEnd={map => setZoom([map.getZoom()])}
        style={satelliteBase ? styles.satellite : styles.light}
        zoom={zoom}
      >
        {mobile !== true && (
          <DHIMapControl
            accessToken={accessToken}
            satelliteBase={satelliteBase}
            zoom={zoom}
            resetView={resetView}
            setBounds={setBounds}
            setSatelliteBase={setSatelliteBase}
            setZoom={setZoom}
          />
        )}
      </Mapbox>
    </>
  );
};

export default Map;
