import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
// import FloodExtentLegend from 'assets/images/flood_extent_legend.jpg';
import moment from 'moment';

// const legendRange = ['0%', '20%', '40%', '60%', '80%', '100%'];

// const colorbarStyle = {
//   width: '100%',
//   height: 8,
//   borderRadius: 4,
// };

// const tableStyle = {
//   width: '100%',
//   fontSize: 10,
//   textAlign: 'center',
// };

const legendStyle = {
  width: '100%',
  height: 8,
  borderRadius: 4,
  backgroundColor: '#0B4566',
  marginTop: 6,
};

const FloodExtentProduct = ({ title, date }) => {
  return (
    <Box display="block" width={'100%'}>
      <Typography variant="subtitle1">
        {moment(date, 'YYYYMMDD').format('Do MMM YYYY')} in {title}
      </Typography>
      <Box pt={1}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <div style={legendStyle}></div>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              style={{ paddingLeft: 16, marginBottom: 0 }}
            >
              Flooded area
            </Typography>
          </Grid>
        </Grid>
        {/* <img src={FloodExtentLegend} alt="" style={colorbarStyle} /> */}
      </Box>
      {/* {legendRange && (
        <table style={tableStyle}>
          <tbody>
            <tr>
              {legendRange.map(v => (
                <td key={v}>{v}</td>
              ))}
            </tr>
          </tbody>
        </table>
      )} */}
    </Box>
  );
};

export default FloodExtentProduct;
