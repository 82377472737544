import React, { useEffect, useState } from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { useLocation } from 'react-router-dom';
import { DemoContext } from 'Demo';

const DataLayer = () => {
  const {
    state: { location, products },
  } = React.useContext(DemoContext);

  const [url, setUrl] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    location === undefined ? setUrl() : updateRaster();
    // eslint-disable-next-line
  }, [location]);

  const updateRaster = () => {
    const urlParam = pathname.split('/')[2];
    const comp = products.find(prod => prod.category === urlParam);

    let tiles =
      'https://bvzg0ogeii.execute-api.eu-central-1.amazonaws.com/development/singleband/floodmetrics/' +
      comp.locations[location].product +
      '/' +
      comp.locations[location].date +
      '/' +
      comp.band +
      '/{z}/{x}/{y}.png?colormap=' +
      comp.locations[location].colormap;

    if (comp.band === 'freq' && location === 0) {
      tiles = comp.locations[0].data;
    }
    setUrl({
      type: 'raster',
      tiles: [tiles],
      attribution:
        '<a href="https://sentinel.esa.int/web/sentinel/home" target="blank">' +
        'Contains modified Copernicus Sentinel data ' +
        new Date().getFullYear() +
        ' ' +
        '</a>',
      tileSize: 256,
    });
  };

  return (
    <>
      {url && (
        <>
          <Source id="source_id" tileJsonSource={url} />
          <Layer type="raster" id="layer_id" sourceId="source_id" />
        </>
      )}
    </>
  );
};

export default DataLayer;
