import React from 'react';

import DemoMap from 'components/DemoMap';
import DemoSidebar from 'components/DemoSidebar';
import DemoSidebarControl from 'components/DemoSidebar/DemoSidebarControl';
import { Box } from '@material-ui/core';
import { useWindowDimensions, kazakhstanColormap } from 'data/utils';
export const DemoContext = React.createContext();

const computeColormap = range => {
  let str = '{';
  for (let i = range[0]; i <= range[1]; i++) {
    str += '"' + i + '":"0B4566"';
    if (i < range[1]) str += ',';
  }
  str += '}';
  return str;
};

const demoExtent = {
  band: 'extent',
  category: 'floodextent',
  color: '#0B4566',
  title: 'Flood extent',
  locations: [
    {
      product: 'amposta_spain',
      title: 'Amposta, Spain',
      date: '20200121',
      colormap:
        'explicit&explicit_color_map=' +
        encodeURIComponent(computeColormap([90, 100])),

      bounds: [
        [1.0463462919315134, 40.91179531193018],
        [0.29887914484405087, 40.42948508451508],
      ],
      latLng: { lng: 0.3800295946825827, lat: 40.859107579671985 },
      zoom: 10,
    },
    {
      product: 'martaban',
      title: 'Martaban, Myanmar',
      date: '20190809',
      colormap:
        'explicit&explicit_color_map=' +
        encodeURIComponent(computeColormap([90, 100])),

      bounds: [
        [97.87636892545873, 16.738168086031976],
        [97.24830836995329, 16.225805679743658],
      ],
      latLng: { lng: 97.29475138373965, lat: 16.667201079590725 },
      zoom: 10,
    },
    {
      product: 'milano',
      title: 'Milano, Italy',
      date: '20191125',
      colormap:
        'explicit&explicit_color_map=' +
        encodeURIComponent(computeColormap([90, 100])),

      bounds: [
        [9.890699376740912, 45.58670093250248],
        [8.148537896670064, 44.53987361693805],
      ],
      latLng: { lng: 8.283054987393399, lat: 45.30795953881133 },
      zoom: 10,
    },
    {
      product: 'murcia_spain',
      title: 'Murcia, Spain',
      date: '20190916',
      colormap:
        'explicit&explicit_color_map=' +
        encodeURIComponent(computeColormap([90, 100])),

      bounds: [
        [-0.42214367030345556, 38.378757713144836],
        [-1.7552561606561028, 37.48422871097863],
      ],
      latLng: { lng: -1.4693627432393441, lat: 38.3577478913235 },
      zoom: 10,
    },
    {
      product: 'shoalhaven',
      title: 'Shoalhaven, Australia',
      date: '20200210',
      colormap:
        'explicit&explicit_color_map=' +
        encodeURIComponent(computeColormap([90, 100])),

      bounds: [
        [150.88296865622283, -34.74095497581216],
        [150.42868968991309, -35.05792546605908],
      ],
      latLng: { lng: 150.48112630154736, lat: -34.737853383485515 },
      zoom: 10,
    },
  ],
};

const demoDEM = {
  band: '??dem??',
  category: 'dem',
  color: '#10E7DC',
  title: 'Digital elevation model',
  locations: [],
};

const demoFrequency = {
  band: 'freq',
  category: 'floodfrequency',
  color: '#00A4EC',
  title: 'Surface water frequency',
  locations: [
    {
      product: 'kazakhstan',
      title: 'Kazakhstan',
      date: '20191113',
      data:
        'https://adrdk4vcyk.execute-api.eu-central-1.amazonaws.com/development/singleband/kazakhstan/water-frequency-multiannual/20181231/freq-30m/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map=' +
        encodeURIComponent(JSON.stringify(kazakhstanColormap)),
      colormap: 'gnbu_r',
      bounds: [
        [62.94628347354117, 55.3533138473598],
        [70.93444818970147, 48.47251791036095],
      ],
      latLng: { lng: 65, lat: 52 },
      zoom: 10,
    },
    {
      product: 'malaysia',
      title: 'Malaysia',
      date: '20200514',
      colormap: 'gnbu',
      bounds: [
        [115.98288376021338, 5.677455513088518],
        [115.20503278721498, 5.060835231352613],
      ],
      latLng: { lng: 115.31848119349547, lat: 5.556168744742251 },
      zoom: 10,
    },
    {
      product: 'tanzania',
      title: 'Tanzania',
      date: '20200520',
      colormap: 'gnbu',
      bounds: [
        [38.5849400484945, -7.110735476043132],
        [37.707728795733914, -8.469046902815592],
      ],
      latLng: { lng: 37.77313915543675, lat: -7.584532441008093 },
      zoom: 10,
    },
    {
      product: 'thailand',
      title: 'Thailand',
      date: '20191028',
      colormap: 'gnbu_r',
      bounds: [
        [107.81843464214018, 21.35574635167474],
        [95.03933916795307, 10.929464246400627],
      ],
      latLng: { lng: 97.1181776597424, lat: 20.74229214830156 },
      zoom: 10,
    },
  ],
};

const Demo = ({ match }) => {
  const { width } = useWindowDimensions();
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [location, setLocation] = React.useState();

  const demoProductsDEM = demoDEM;
  const demoProductsExtent = demoExtent;
  const demoProductsFrequency = demoFrequency;
  const products = [demoProductsDEM, demoProductsExtent, demoProductsFrequency];

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [openDrawer]);

  return (
    <DemoContext.Provider
      value={{
        state: {
          demoProductsDEM,
          demoProductsExtent,
          demoProductsFrequency,
          location,
          openDrawer,
          products,
        },
        actions: { setLocation, setOpenDrawer },
      }}
    >
      <div style={{ width: '100vw', height: window.innerHeight }}>
        {width > 600 ? (
          <Box display="flex" height={1} width={1}>
            <Box flexGrow={1}>
              <DemoMap mobile={false} />
            </Box>
            {openDrawer && (
              <Box
                width={360}
                style={{ borderLeft: '1px solid #DBE4E9' }}
                zIndex={'tooltip'}
              >
                <DemoSidebar match={match} />
              </Box>
            )}
            <Box
              width={37}
              style={{ borderLeft: '1px solid #DBE4E9' }}
              zIndex={'tooltip'}
            >
              <DemoSidebarControl />
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" height={1} width={1}>
            <Box width={1} flexGrow={1}>
              <DemoMap mobile={true} />
            </Box>
            {openDrawer && (
              <Box height={'50%'} width={1} zIndex={'tooltip'}>
                <DemoSidebar match={match} />
              </Box>
            )}
            <Box
              height={36}
              style={{ borderTop: '1px solid #DBE4E9' }}
              zIndex={'tooltip'}
            >
              <DemoSidebarControl mobile={true} />
            </Box>
          </Box>
        )}
      </div>
    </DemoContext.Provider>
  );
};

export default Demo;
