import React from 'react';
import classnames from 'classnames';
import { Marker } from 'react-mapbox-gl';
import { DemoContext } from 'Demo';
import { makeStyles } from '@material-ui/core';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

const markerStyle = makeStyles({
  marker: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'all .3s',
    '&:hover': {
      width: 24,
      height: 24,
    },
  },
  markerSmall: {
    width: 12,
    height: 12,
    '&:hover': {
      width: 16,
      height: 16,
    },
  },
  markerLarge: {
    width: 24,
    height: 24,
    '&:hover': {
      width: 28,
      height: 28,
    },
  },
});

const ProductMarkers = () => {
  const {
    actions: { setLocation },
    state: { demoProductsExtent, demoProductsDEM, demoProductsFrequency },
  } = React.useContext(DemoContext);

  const classes = markerStyle();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const urlParam = pathname.split('/')[2];

  return (
    <>
      {demoProductsExtent &&
        demoProductsExtent.locations.length > 0 &&
        demoProductsExtent.locations.map(({ latLng, title }, i) => {
          const { category, color } = demoProductsExtent;
          return (
            <Marker coordinates={latLng} anchor="center" key={i}>
              <Link to={`${url}/${category}`}>
                <div
                  style={{ backgroundColor: color }}
                  className={classnames({
                    [classes.marker]: true,
                    [classes.markerSmall]: urlParam !== undefined,
                    [classes.markerLarge]: urlParam === category,
                  })}
                  onClick={() => setLocation(i)}
                />
              </Link>
            </Marker>
          );
        })}

      {demoProductsDEM &&
        demoProductsDEM.locations.length > 0 &&
        demoProductsDEM.locations.map(({ latLng, title }, i) => {
          const { category, color } = demoProductsDEM;
          return (
            <Marker coordinates={latLng} anchor="center" key={i}>
              <Link to={`${url}/${category}`}>
                <div
                  style={{ backgroundColor: color }}
                  className={classnames({
                    [classes.marker]: true,
                    [classes.markerSmall]: urlParam !== undefined,
                    [classes.markerLarge]: urlParam === category,
                  })}
                  onClick={() => setLocation(i)}
                />
              </Link>
            </Marker>
          );
        })}

      {demoProductsFrequency &&
        demoProductsFrequency.locations.length > 0 &&
        demoProductsFrequency.locations.map(({ latLng, title }, i) => {
          const { category, color } = demoProductsFrequency;
          return (
            <Marker coordinates={latLng} anchor="center" key={i}>
              <Link to={`${url}/${category}`}>
                <div
                  style={{ backgroundColor: color }}
                  className={classnames({
                    [classes.marker]: true,
                    [classes.markerSmall]: urlParam !== undefined,
                    [classes.markerLarge]: urlParam === category,
                  })}
                  onClick={() => setLocation(i)}
                />
              </Link>
            </Marker>
          );
        })}
    </>
  );
};

export default ProductMarkers;
