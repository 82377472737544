import React from 'react';
import { ReactComponent as MinusIcon } from '@mike/mike-shared-frontend/media/icons/Minus.svg';
import { ReactComponent as PlusIcon } from '@mike/mike-shared-frontend/media/icons/Plus.svg';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { iconStyle } from 'styles/global';

import 'styles/searchbar.css';

const ZoomControl = ({ setZoom, zoom }) => {
  const classes = iconStyle();

  return (
    <Grid
      container
      style={{ width: 36, boxShadow: 'rgba(0, 0, 0, 0.16) 4px 0px 4px' }}
    >
      <Grid container>
        <Tooltip placement="right" title={'Zoom in'}>
          <IconButton
            className={classes.button}
            onClick={() => setZoom([zoom[0] + 1])}
          >
            <PlusIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container>
        <Tooltip placement="right" title={'Zoom out'}>
          <IconButton
            className={classes.button}
            onClick={() => setZoom([zoom[0] - 1])}
          >
            <MinusIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ZoomControl;
