import React from 'react';
import _ from 'lodash';
import ResolutionSelector from './ResolutionSelector';
import VolumeDisclaimerAccordion from '../VolumeDisclaimers/VolumeDisclaimerAccordion';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '@mike/mike-shared-frontend/media/icons/Calendar.svg';
import { AppContext } from 'App';
import { Box, Grid, Typography } from '@material-ui/core';

export default function DateSelector({ date, index }) {
  const {
    state: { area, productExtent },
    actions: { setProductExtent },
  } = React.useContext(AppContext);

  const { priceCap } = productExtent;

  React.useEffect(() => {
    calcNewPrices(); // eslint-disable-next-line
  }, [area]);

  const calcNewPrices = async () => {
    let obj = productExtent;
    obj.dates[index].price = await calcPrice(obj.dates[index]);
    setProductExtent({ ...obj });
  };

  const handleDateChange = async _date => {
    let obj = _.cloneDeep(productExtent);
    obj.dates[index].date = _date;
    obj.dates[index].price = await calcPrice(obj.dates[index]);
    setProductExtent({ ...obj });
  };

  const handleCheckbox = async i => {
    let obj = productExtent;
    obj.dates[index].resolution[i].checked = !obj.dates[index].resolution[i]
      .checked;
    obj.dates[index].price = await calcPrice(obj.dates[index]);
    setProductExtent({ ...obj });
  };

  const calcPrice = async _obj => {
    if (_obj.date === null) return [null, null];

    let min = 0;
    let max = 0;

    const discount =
      index > 0 && productExtent.dates[0].price[0] > 0 ? 0.95 : 1;

    _obj.resolution.forEach(({ checked, formula }) => {
      if (checked) {
        min += formula.low(area.sqm) * discount;
        max += formula.high(area.sqm) * discount;
      }
    });

    if (max > priceCap) return [priceCap, priceCap];
    else return [parseInt(min * 0.1) * 10, parseInt(max * 0.1) * 10];
  };

  return (
    <Box style={{ borderBottom: '1px solid #DBE4E9' }} pb={1}>
      <Grid container spacing={0} style={{ paddingTop: 8 }}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            variant="inline"
            placeholder={'Select date'}
            value={
              productExtent.dates[index].date && productExtent.dates[index].date
            }
            onChange={_date => handleDateChange(_date)}
            disableToolbar
            // initialFocusedDate={focusDate}
            format="dd/MM/yyyy"
            keyboardIcon={<CalendarIcon style={{ width: 24, height: 24 }} />}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingTop: 8 }}>
          {date.price[1] >= priceCap ? (
            <VolumeDisclaimerAccordion />
          ) : (
            <Typography variant="body2" style={{ textAlign: 'right' }}>
              {date.price[0] === null || date.price[0] === 0
                ? ' - '
                : `€${date.price[0].toLocaleString()} - €${date.price[1].toLocaleString()}`}
            </Typography>
          )}
        </Grid>
      </Grid>

      {date.date !== null && (
        <ResolutionSelector
          date={date}
          handleCheckbox={handleCheckbox}
          index={index}
        />
      )}
    </Box>
  );
}
