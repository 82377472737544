import React, { useEffect } from 'react';
import DateSelector from './DateSelector';

import _ from 'lodash';
import { ReactComponent as PlusIcon } from '@mike/mike-shared-frontend/media/icons/Plus.svg';
import { ReactComponent as MinusIcon } from '@mike/mike-shared-frontend/media/icons/Minus.svg';
import { productStyle } from 'styles/global';
import { Box, Grid, Typography } from '@material-ui/core';
import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';
import { AppContext } from 'App';
import { HomeContext } from 'Home';

const iconStyle = {
  width: 24,
  height: 24,
  cursor: 'pointer',
};

export default function FloodExtent() {
  const {
    state: { productExtent },
    actions: { resetProducts, setProductExtent },
  } = React.useContext(AppContext);

  const {
    actions: { setDialogue },
  } = React.useContext(HomeContext);

  const classes = productStyle();
  const floodExtentProduct = defaultProducts[0];
  const { priceCap } = productExtent;

  useEffect(() => {
    let _price = calcSum();
    let obj = _.cloneDeep(productExtent);

    if (obj.price[0] !== _price[0] && obj.price[1] !== _price[1]) {
      obj.price = _price;
      setProductExtent({ ...obj });
    }

    // eslint-disable-next-line
  }, [productExtent]);

  const calcSum = () => {
    const min = productExtent.dates
      .filter(date => date.date)
      .reduce((acc, item) => acc + item.price[0], 0);

    const max = productExtent.dates
      .filter(date => date.date)
      .reduce((acc, item) => acc + item.price[1], 0);

    if (max > priceCap) return [priceCap, priceCap];
    else return [min, max];
  };

  const appendDates = () => {
    let obj = productExtent;
    obj.dates.push(_.cloneDeep(productExtent.dateTemplate));
    setProductExtent({ ...obj });
  };

  const removeDates = () => {
    let obj = productExtent;
    obj.dates.splice(obj.dates.length - 1, 1);
    setProductExtent({ ...obj });
  };

  return (
    <Box>
      <Typography variant="body2" paragraph>
        Assess the extent of flooding’s in near real time or for any historic
        flooding event (depending on availability of archived imagery). Our
        operational flood extent maps can be acquired few hours after a flooding
        has occurred and delivered in less than 24-48 hours globally (different
        solutions apply). Select the date(s) for your required flood maps below.{' '}
        <span
          onClick={() => setDialogue(floodExtentProduct)}
          className={classes.readMore}
        >
          Read more.
        </span>
      </Typography>

      <Typography variant="subtitle1">Request data for period(s):</Typography>
      {productExtent.dates.map((date, index) => (
        <DateSelector key={index} date={date} index={index} />
      ))}

      {productExtent && productExtent.dates && productExtent.dates.length > 0 && (
        <Box>
          <Grid container>
            <Grid item xs={10} />
            <Grid item xs={1}>
              {productExtent.dates.length > 1 ? (
                <MinusIcon onClick={removeDates} style={iconStyle} />
              ) : productExtent.dates[0].date !== null ? (
                <MinusIcon
                  onClick={() => resetProducts(productExtent.id)}
                  style={iconStyle}
                />
              ) : null}
            </Grid>

            <Grid item xs={1}>
              <PlusIcon onClick={appendDates} style={iconStyle} />
            </Grid>
          </Grid>

          {/* 
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Typography
                onClick={() => resetProducts(productExtent.id)}
                className={classes.clearDays}
                style={{ cursor: 'pointer' }}
              >
                Clear data
              </Typography>
            </Grid>

            <Grid item xs={10}>
              
                  <Typography className={classes.disclaimer}>
                    {'Minimum order for ' +
                      1234 +
                      'm² is: ' +
                      567 +
                      'km² / €' +
                      890}
                  </Typography>
            </Grid>
          </Grid> */}
        </Box>
      )}
    </Box>
  );
}
