import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import imageExtent from 'assets/images/product_flood_extent.png';

const FloodExtent = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper
      style={{ flexGrow: 1, overflow: 'hidden', backgroundColor: '#F2F5F7' }}
    >
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12} sm={6}>
            <Box py={1}>
              <Typography variant={'h4'} className={landingpage.productTitle}>
                Flood extents
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              We use the latest machine learning technology and advanced image
              analysis to accurately map the spatial extent of floodings.
            </Typography>
            <Box my={2}></Box>
            <Typography variant={'body1'}>
              By fusing both optical and radar satellite data, we map flooded
              areas with high precision, and regardless of weather conditions,
              cloud cover and time of day.
            </Typography>
            <Box my={2}></Box>
            <Typography variant={'body1'}>
              Our optimized and automated processing chains ensures fast,
              consistent, reliable and timely delivery of flood extent products.
              The product is available at any location, globally, and can be
              delivered in near-real time for ongoing flooding events or any
              historical flooding in the last 25 years (depending on data
              availability).
            </Typography>
            <Box my={1}>
              <img
                src={imageExtent}
                alt="flood extent"
                style={{ width: '100%' }}
              />
            </Box>
            <Typography style={{ textAlign: 'right', fontSize: 10 }}>
              Water extent of flood after cyclone Idai, Mozambique 2019
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default FloodExtent;
