import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { landingpageStyle } from 'styles/landingpage';

import iconDem from 'assets/images/icon_dem.png';
import iconFrequency from 'assets/images/icon_frequency.png';
import iconExtent from 'assets/images/icon_extent.png';

const products = [
  { title: 'Flood extents', icon: iconExtent },
  { title: 'Digital elevation Model', icon: iconDem },
  { title: 'Surface water frequency ', icon: iconFrequency },
];

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
});

const textCenter = { textAlign: 'center' };

const ProductOverview = ({ padding }) => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12}>
            <Typography
              variant={'h4'}
              className={landingpage.h4}
              style={textCenter}
            >
              3 different flooding products in a single portal
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box py={1}>
              <Typography variant={'body1'} style={textCenter}>
                Flood Metrics uses best-in-class satellite imagery for accurate
                flood extent and surface water frequency mapping and provides
                access to industry leading Digital Elevation Models.
              </Typography>
            </Box>
          </Grid>
          {products.map(({ title, icon }, i) => (
            <Grid item xs={12 / products.length} style={textCenter} key={i}>
              <Box style={{ minHeight: 42 }}>
                <img src={icon} alt={title} style={{ width: 75 }} />
              </Box>
              <Typography variant={'subtitle1'} style={textCenter}>
                {title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProductOverview;
