import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { AppContext } from 'App';

export default function DemDetails() {
  const {
    state: { productDEM },
  } = React.useContext(AppContext);

  const { price, priceCap, resolutionRange } = productDEM;

  return (
    <Box pt={1}>
      {productDEM && price && price[1] !== null && price[0] !== 0 && (
        <>
          <Box>
            <Typography variant="subtitle1">
              Digital Elevation Model:
            </Typography>

            <Typography variant="body2" style={{ margin: 0 }}>
              {price[0] >= priceCap
                ? 'Price: Volume discount applies'
                : `Price range: €${price[0].toLocaleString()} - €${price[1].toLocaleString()}`}
            </Typography>
          </Box>

          {resolutionRange.map(
            ({ res, sum }, i) =>
              sum.min !== null &&
              sum.min !== 0 && (
                <Typography variant="body2" style={{ margin: 0 }} key={i}>
                  {sum.min >= priceCap
                    ? `${res} meter resolution = Volume discount applies`
                    : `${res} meter resolution = €${sum.min.toLocaleString()} - €${sum.max.toLocaleString()}`}
                </Typography>
              )
          )}
        </>
      )}
    </Box>
  );
}
