import React from 'react';
import classnames from 'classnames';

import { AppContext } from 'App';
import { Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import { iconStyle } from 'styles/global';

import { ReactComponent as DownloadIcon } from '@mike/mike-shared-frontend/media/icons/Download.svg';
import { ReactComponent as InfoIcon } from '@mike/mike-shared-frontend/media/icons/Info.svg';
import { ReactComponent as LeftIcon } from '@mike/mike-shared-frontend/media/icons/Left.svg';
import { ReactComponent as RightIcon } from '@mike/mike-shared-frontend/media/icons/Right.svg';
import { ReactComponent as SupportIcon } from '@mike/mike-shared-frontend/media/icons/Support.svg';
import { ReactComponent as UpIcon } from '@mike/mike-shared-frontend/media/icons/Up.svg';
import { ReactComponent as DownIcon } from '@mike/mike-shared-frontend/media/icons/Down.svg';

const icons = [
  { id: 1, component: DownloadIcon, tooltip: 'Get your data' },
  { id: 0, component: InfoIcon, tooltip: 'Information' },
  { id: 2, component: SupportIcon, tooltip: 'Support and legal' },
];

const SidebarControl = ({ mobile }) => {
  const {
    actions: { setOpenDrawer, setSidebarComponent },
    state: { openDrawer, sidebarComponent },
  } = React.useContext(AppContext);

  const classes = iconStyle();

  const handleClick = id => {
    sidebarComponent !== id && setSidebarComponent(id);
    !openDrawer && setOpenDrawer(true);
  };

  return (
    <Box
      flexGrow={1}
      display="flex"
      flexDirection={mobile === true ? 'row' : 'column'}
      style={{ overflowY: 'auto', height: ' 100%', backgroundColor: '#fff' }}
      justifyContent="space-between"
    >
      <Box>
        <IconButton
          className={classes.button}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          {openDrawer === true && mobile !== true && (
            <RightIcon className={classes.icon} />
          )}
          {openDrawer === false && mobile !== true && (
            <LeftIcon className={classes.icon} />
          )}
          {openDrawer === true && mobile === true && (
            <DownIcon className={classes.icon} />
          )}
          {openDrawer === false && mobile === true && (
            <UpIcon className={classes.icon} />
          )}
        </IconButton>
      </Box>
      <Box p={0} m={0}>
        <Grid container spacing={0}>
          {icons.map(({ id, component: Component, tooltip }) => (
            // <Grid key={id} item xs={mobile === true ? 4 : 12}>
            <Tooltip placement="left" title={tooltip} key={id}>
              <IconButton
                className={classes.button}
                onClick={() => handleClick(id)}
              >
                <Component
                  className={classnames({
                    [classes.icon]: true,
                    [classes.activeIcon]: openDrawer && sidebarComponent === id,
                  })}
                />
              </IconButton>
            </Tooltip>
            // </Grid>
          ))}
        </Grid>
      </Box>
      <Box></Box>
    </Box>
  );
};

export default SidebarControl;
