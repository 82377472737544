import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { AppContext } from 'App';

export default function FloodFrequencyDetails() {
  const {
    state: { productFrequency },
  } = React.useContext(AppContext);

  const { months, years, price, priceCap } = productFrequency;

  return (
    <>
      {years && years[0] && (years[0] !== 0 || years[0] !== null) && (
        <Box pt={2}>
          <Typography variant="subtitle1">Surface water frequency:</Typography>

          <Typography variant="body2" style={{ margin: 0 }}>
            {price && price[0] !== null && price[0] === priceCap
              ? 'Price: Volume discount applies'
              : 'Price range: €' +
                price[0].toLocaleString() +
                ' - €' +
                price[1].toLocaleString()}
          </Typography>

          <Typography variant="body2" style={{ margin: 0 }}>
            {'Years:' + years.map(year => ` ${year}`)}
          </Typography>

          {months && (
            <Typography variant="body2" style={{ margin: 0 }}>
              {'Include months: ' +
                Object.keys(months)
                  .filter(key => months[key] && key)
                  .toString()
                  .replace(/,/g, ', ')}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
}
