import MapboxDraw from '@mapbox/mapbox-gl-draw';

import DrawRectangle from './partials/DrawRectangle';
import RadiusMode from './partials/DrawRadius';

export const draw = new MapboxDraw({
  displayControlsDefault: false,
  modes: Object.assign(
    {
      draw_rectangle: DrawRectangle,
      draw_circle: RadiusMode,
    },
    MapboxDraw.modes
  ),
});
