import React from 'react';
import { defaultProducts } from './defaultProducts';
import {
  AppBar,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #DBE4E9',
    color: '#0B4566',
    minHeight: 40,
  },
  tab: {
    fontSize: 9,
    minHeight: 40,
    minWidth: '33.3%',
    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      fontWeight: 700,
    },
  },
  appbar: {
    boxShadow: 'none',
  },

  // textLink: {
  //   color: '#00A4EC',
  //   fontWeight: 700,
  //   textDecoration: 'none',
  //   '&:hover': {
  //     textDecoration: 'underline',
  //   },
  // },

  buttonSecondary: {
    width: '50%',
    backgroundColor: '#fff',
    border: '2px solid #00A4EC ',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(0, 164, 236, 0.08)',
    },
  },
  link: {
    color: '#00A4EC',
    fontWeight: 700,
    textDecoration: 'none',
  },
}));

export default function Info() {
  const products = [defaultProducts[0], defaultProducts[1], defaultProducts[2]];
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Typography variant="h6">Info about the flood products</Typography>

      <AppBar position="static" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{
            style: { backgroundColor: '#0B4566' },
          }}
        >
          {products.map(({ title }, i) => (
            <Tab
              className={classes.tab}
              key={title}
              label={title}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </AppBar>

      <Box p={2}>
        {products &&
          products.map(({ description, image, link, title }, i) => (
            <Paper key={i}>
              {i === value && (
                <>
                  <Typography variant={'subtitle2'}>{title}</Typography>
                  <Box pt={1}>{description}</Box>
                  <img src={image} style={{ width: '100%' }} alt={title} />

                  {/* 
                  <Typography variant="body2">
                    <Link to={'/showcase/' + link} className={classes.textLink}>
                      Visit showcase for {title}
                    </Link>
                  </Typography> 
                  */}

                  <Box pt={2}></Box>
                  <Typography variant="body2">
                    <Button className={classes.buttonSecondary}>
                      <Link to={'/showcase/' + link} className={classes.link}>
                        Visit online showcase
                      </Link>
                    </Button>
                  </Typography>
                </>
              )}
            </Paper>
          ))}
      </Box>
    </Box>
  );
}
