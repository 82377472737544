import React from 'react';
import { ReactComponent as ExpandIcon } from '@mike/mike-shared-frontend/media/icons/Expand.svg';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { iconStyle } from 'styles/global';

const ResetViewControl = ({ resetView }) => {
  const classes = iconStyle();

  return (
    <Grid
      container
      style={{ width: 36, boxShadow: 'rgba(0, 0, 0, 0.16) 4px 0px 4px' }}
    >
      <Grid container>
        <Tooltip placement="right" title={'Reset view'}>
          <IconButton className={classes.button} onClick={() => resetView()}>
            <ExpandIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ResetViewControl;
