import React from 'react';
import ReactMapboxGl from 'react-mapbox-gl';
import BaseLayerControl from 'components/Map/DHIMapControl/partials/BaseLayerControl';
import DataLayer from './partials/DataLayer';
import Loader from 'components/Loader';
import ProductMarkers from './partials/ProductMarkers';
import ResetViewControl from 'components/Map/DHIMapControl/partials/ResetViewControl';
import ZoomControl from 'components/Map/DHIMapControl/partials/ZoomControl';
import { Box } from '@material-ui/core';
import { DemoContext } from 'Demo';
import { useLocation } from 'react-router-dom';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const { token: accessToken, styles } = require('config.json');

const Mapbox = ReactMapboxGl({
  accessToken,
  minZoom: 2,
  maxZoom: 15,
});

const DemoMap = ({ mobile }) => {
  const {
    state: { location, products },
  } = React.useContext(DemoContext);

  const DEFAULT_BOUNDS = [
    [90, 72],
    [-40, -32],
  ];
  const [zoom, setZoom] = React.useState([2]);
  const [bounds, setBounds] = React.useState(DEFAULT_BOUNDS);
  const [loading, setLoading] = React.useState(true);
  const [satelliteBase, setSatelliteBase] = React.useState(false);

  const { pathname } = useLocation();
  const resetView = () => setBounds([...DEFAULT_BOUNDS]);

  React.useEffect(() => {
    const urlParam = pathname.split('/')[2];
    if (location >= 0 && urlParam) {
      products.forEach(prod => {
        if (prod.locations[location] && prod.category === urlParam) {
          setBounds(prod.locations[location].bounds);
        }
      });
    } else if (location === undefined) setBounds(DEFAULT_BOUNDS);
    // eslint-disable-next-line
  }, [location, pathname]);

  // const [viewport, setViewport] = React.useState();

  // const urlParam = pathname.split('/')[2];
  // const comp = products.find(prod => prod.category === urlParam);

  return (
    <>
      <Loader isLoading={loading} isMajorLoading={loading} />
      <Mapbox
        className="mapboxgl-map"
        containerStyle={{ height: '100%', width: '100%' }}
        fitBounds={bounds}
        onStyleLoad={() => setLoading(false)}
        onZoomEnd={map => setZoom([map.getZoom()])}
        // onClick={map => console.log(map.getBounds())}
        // onViewportChange={setViewport}
        style={satelliteBase ? styles.satellite : styles.light}
        zoom={zoom}
      >
        {mobile !== true && (
          <Box
            width={36}
            height={'100vh'}
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <BaseLayerControl
              satelliteBase={satelliteBase}
              setSatelliteBase={setSatelliteBase}
            />
            <ZoomControl setZoom={setZoom} zoom={zoom} />
            <ResetViewControl resetView={resetView} />
          </Box>
        )}

        <DataLayer />
        <ProductMarkers />
      </Mapbox>
    </>
  );
};

export default DemoMap;
