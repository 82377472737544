import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';

const firstColumn = [
  'Search, request and purchase Accurate flood products and DEM data directly from our online store',
  'Keep your data costs low Search for data covering your area of interest and pay by the square kilometer',
  'Ready-to-use data We provide easy access to the most advanced satellite-based flood products, delivered in ready-to-use data formats',
];

const secondColumn = [
  'Operational flood data unaffected by clouds and weather We fuse both optical and radar satellite data to ensure seamless coverage of your area, regardless of cloud cover, and we deliver flood extent products in under an hour after we receive the raw satellite data.',
  'Globally available and local scale data Our products are available anywhere in the world and we provide both medium and very high-resolution data products for your local area.',
];

const BulletPoints = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper
      style={{ flexGrow: 1, overflow: 'hidden', backgroundColor: '#F2F5F7' }}
    >
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={0} style={{ padding: padding }}>
          <Grid item xs={12}>
            <Typography
              variant={'h4'}
              className={landingpage.h4}
              paragraph
              style={{ textAlign: 'center' }}
            >
              Easy access to high quality flood products and Digital Elevation
              Models (DEM)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={'body1'}
              paragraph
              style={{ textAlign: 'center', paddingBottom: 8 }}
            >
              Flood Metrics puts our operational flood products and DEM’s at
              your fingertips. It enables you to:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container>
            {firstColumn &&
              firstColumn.map((point, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={2} sm={1}>
                    <DotIcon className={landingpage.icon} />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography variant={'body1'} paragraph>
                      {point}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
          <Grid item xs={12} sm={6} container>
            {secondColumn &&
              secondColumn.map((point, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={2} sm={1}>
                    <DotIcon className={landingpage.icon} />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography variant={'body1'} paragraph>
                      {point}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default BulletPoints;
