export const directionRow = {
  display: 'flex',
  flexDirection: 'row'
};

export const directionColumn = {
  display: 'flex',
  flexDirection: 'column'
};

export const justifySpaceBetween = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const justifySpaceAround = {
  display: 'flex',
  justifyContent: 'space-around'
};

export const justifyEnd = {
  display: 'flex',
  justifyContent: 'flex-end'
};

export const justifyCenter = {
  display: 'flex',
  justifyContent: 'center'
};

export const alignCenter = {
  display: 'flex',
  alignItems: 'center'
};
