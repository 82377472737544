import React from 'react';
import BaseLayerControl from './partials/BaseLayerControl';
import ResetViewControl from './partials/ResetViewControl';
import SearchControl from './partials/SearchControl';
import ZoomControl from './partials/ZoomControl';
import ZoomToAreaControl from './partials/ZoomToAreaControl';
import { Box } from '@material-ui/core';

const DHIMapControl = ({
  accessToken,
  resetView,
  satelliteBase,
  setBounds,
  setSatelliteBase,
  setZoom,
  zoom,
}) => {
  return (
    <>
      <Box mt={4}>
        <SearchControl setBounds={setBounds} accessToken={accessToken} />
      </Box>
      <Box
        style={{
          position: 'fixed',
          left: 0,
          top: `calc(50vh - 72px)`,
        }}
      >
        <BaseLayerControl
          satelliteBase={satelliteBase}
          setSatelliteBase={setSatelliteBase}
        />
        <ZoomControl setZoom={setZoom} zoom={zoom} />
        <ZoomToAreaControl />
        <ResetViewControl resetView={resetView} />
      </Box>
    </>
  );
};

export default DHIMapControl;
