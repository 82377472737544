import React from 'react';
import Geocoder from 'react-mapbox-gl-geocoder';
import { ReactComponent as CloseIcon } from '@mike/mike-shared-frontend/media/icons/CloseS.svg';
import { ReactComponent as SearchIcon } from '@mike/mike-shared-frontend/media/icons/Search.svg';
import { IconButton, Grid } from '@material-ui/core';
import { iconStyle } from 'styles/global';

import 'styles/searchbar.css';

const SearchControl = ({ setBounds, accessToken }) => {
  const [searchbar, toggleSearchbar] = React.useState(true);
  const classes = iconStyle();

  const onSelected = item => {
    if (item.bbox)
      setBounds([
        [item.bbox[0], item.bbox[1]],
        [item.bbox[2], item.bbox[3]],
      ]);
    else
      setBounds([
        [item.center[0] - 0.0001, item.center[1] - 0.0001],
        [item.center[0] + 0.0001, item.center[1] + 0.0001],
      ]);
    toggleSearchbar(false);
  };

  return (
    <Grid container>
      <Grid item style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 4px 0px 4px' }}>
        <IconButton
          className={classes.button}
          onClick={() => toggleSearchbar(!searchbar)}
        >
          <SearchIcon className={classes.icon} />
        </IconButton>
      </Grid>

      {searchbar && (
        <>
          <Grid item style={{ zIndex: 100 }}>
            <Geocoder
              mapboxApiAccessToken={accessToken}
              viewport={{ viewport: '' }}
              onSelected={(viewport, item) => onSelected(item)}
              hideOnSelect={true}
              queryParams={{ country: '' }}
              clearOnBlur={true}
              initialInputValue={'Search by location'}
            />
          </Grid>
          <Grid item>
            <IconButton
              className={classes.button}
              onClick={() => toggleSearchbar(false)}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SearchControl;
