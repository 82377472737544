import React from 'react';
import Introduction from 'components/Sidebar/Introduction';
import ProductList from 'components/Sidebar/ProductList';
import QuoteDetails from 'components/Sidebar/QuoteDetails';
import Confirmation from 'components/Sidebar/Confirmation';
import { Box } from '@material-ui/core';
import { AppContext } from 'App';

import 'styles/scrollbar.css';

const DataComponent = () => {
  const {
    state: { area, showConfirmation, showQuote },
  } = React.useContext(AppContext);

  return (
    <Box >
      {showConfirmation === true ? (
        <Confirmation />
      ) : area.sqm === null ? (
          <Introduction />
      ) : showQuote === false ? (
        <ProductList />
      ) : (
        <QuoteDetails />
      )}
    </Box>
  );
};

export default DataComponent;
