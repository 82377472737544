import React from 'react';
import { Box, Typography } from '@material-ui/core';
import errorIcon from 'assets/images/error.svg';
import { landingpageStyle } from 'styles/landingpage';

const linkStyle = {
  color: '#00A4EC',
};

const textStyle = {
  color: '#0B4566',
};

const ContactConfirmation = () => {
  const landingpage = landingpageStyle();

  return (
    <Box py={2} style={{ textAlign: 'center' }}>
      <img src={errorIcon} alt="error" style={{ width: 40, height: 40 }} />
      <Box pt={2}></Box>
      <Typography variant={'h4'} className={landingpage.h4}>
        Something has gone wrong
      </Typography>
      <Box py={2}></Box>
      <Typography variant={'body1'} style={textStyle}>
        Please try to contact us again, and if the problem persist contact us
        through{' '}
        <a
          href="https://www.dhi-gras.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          www.dhi-gras.com
        </a>
      </Typography>
    </Box>
  );
};

export default ContactConfirmation;
