import React from 'react';
import DemostrationData from './partials/DemonstrationData';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { HomeContext } from 'Home';
import { productStyle } from 'styles/global';
import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';
// import FloodExtent from 'components/Sidebar/ProductList/FloodExtent';

const buttonStyle = makeStyles({
  buttonMain: {
    width: '100%',
    backgroundColor: '#00A4EC',
    '&:hover': {
      backgroundColor: '#008BEC',
    },
  },
  link: {
    color: '#00A4EC',
    fontWeight: 700,
    textDecoration: 'none',
  },
});

const dialogueLinks = [
  { title: 'Flood extent', product: defaultProducts[0] },
  { title: 'Digital Elevation Models', product: defaultProducts[1] },
  { title: 'Surface Water Frequency', product: defaultProducts[2] },
];

const detailLinks = [
  {
    title: '1. Define your area of interest',
    description: (
      <Typography variant="body2" paragraph>
        Click the ‘Start Drawing’ button below to draw a polygon outlining the
        boundaries of your area of interest.'
      </Typography>
    ),
  },
  {
    title: '2. Select your products',
    description: (
      <Typography variant="body2" paragraph>
        Select your products and define your specific product requirements to
        retrieve an instant price estimate.
      </Typography>
    ),
  },
  {
    title: '3. Get detailed quote',
    description: (
      <Typography variant="body2" paragraph>
        Click the ‘Get detailed quote’ button to send a request for more
        information on the exact costs and availability of your selected
        products.
      </Typography>
    ),
  },
];

// const tutorial = {
//   title: 'Tutorial',
//   description: null,
//   media: (
//     <div>
//       <iframe
//         width="560"
//         height="315"
//         src="https://www.youtube.com/embed/h_OnaIjYjIg"
//         title={'flood metrics tutorial'}
//         frameBorder="0"
//         allow="fullscreen"
//         allowFullScreen
//       />
//     </div>
//   ),
// };

export default function Introduction() {
  const {
    actions: { drawMode, setDialogue },
    state: { loading },
  } = React.useContext(HomeContext);

  const classes = buttonStyle();
  const styles = productStyle();

  return (
    <Box >
      <Typography variant="h6">Introduction</Typography>
      <Box p={2}>
        <Typography variant="subtitle1" paragraph>
          Welcome to Flood Metrics by DHI
        </Typography>
        <Typography variant="body2" paragraph>
          Flood Metrics is your all-in-one interface for DHI’s state-of-the-art
          satellite-based flood monitoring products, allowing you to search,
          request and purchase our novel satellite-based flood products and
          Digital Elevation Models (DEM) from a single source.
        </Typography>
        <Typography variant="body2" paragraph>
          Flood Metrics provides instant price range estimates for 3 different
          products (
          {dialogueLinks.map(({ title, product }, i) => (
            <React.Fragment key={i}>
              <span
                onClick={() => setDialogue(product)}
                className={styles.popupLink}
              >
                {title}
              </span>
              {i === 0 ? ', ' : i === 1 ? ' and ' : ''}
            </React.Fragment>
          ))}
          ), allowing you to quickly assess costs for your specific area of
          interest. The prices are individually tailored and customized to your
          specific product requirements in terms of spatial resolution, date
          range(s) and other relevant parameters.
        </Typography>
        <Typography variant="body2" paragraph>
          It’s as easy as 1-2-3:
        </Typography>

        {detailLinks &&
          detailLinks.map(({ title }, i) => (
            <Typography
              variant="subtitle1"
              gutterBottom
              key={title}
              onClick={() => setDialogue(detailLinks[i])}
              className={styles.popupLink}
            >
              {title}
            </Typography>
          ))}

        {/* <Typography variant="body2" paragraph>
          You can also watch this{' '}
          <span
            onClick={() => setDialogue(tutorial)}
            className={styles.readMore}
          >
            tutorial video
          </span>{' '}
          for a quick introduction to the process and steps outlined above.
        </Typography> */}
        <Box pt={2} />
        <Button
          className={classes.buttonMain}
          onClick={drawMode('polygon')}
          disabled={loading}
        >
          Start Drawing
        </Button>
      </Box>
      <DemostrationData />
    </Box>
  );
}
