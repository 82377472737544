import React from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { checkboxStyle } from 'styles/global';

const textStyle = {
  paddingTop: 4,
};

export default function ResolutionSelector({ handleCheckbox, date, index }) {
  const classes = checkboxStyle();

  return (
    <Grid container spacing={0} style={{ padding: '8px 0px 0px' }}>
      <Grid item xs={8}>
        <Typography variant="body2" style={textStyle}>
          Select resolution:
        </Typography>
      </Grid>

      {date.resolution.map(({ checked, title }, i) => (
        <Grid
          item
          xs={2}
          key={i}
          onClick={() => handleCheckbox(i)}
          style={{ cursor: 'pointer' }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Checkbox
                checked={checked}
                checkedIcon={
                  <span className={([classes.icon], [classes.checkedIcon])} />
                }
                icon={<span className={[classes.icon]} />}
                style={{ width: 16, height: 16 }}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2" style={textStyle}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
