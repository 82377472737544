import React from 'react';
import _ from 'lodash';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';
import { productStyle } from 'styles/global';
import { Box, Typography } from '@material-ui/core';
import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';
import { AppContext } from 'App';
import { HomeContext } from 'Home';

var initiated = false;

export default function FloodFrequency({ expanded }) {
  const {
    state: { area, productFrequency },
    actions: { setProductFrequency },
  } = React.useContext(AppContext);

  const {
    actions: { setDialogue },
  } = React.useContext(HomeContext);

  const frequencyProduct = defaultProducts[2];
  const classes = productStyle();
  const { priceCap } = productFrequency;

  React.useEffect(() => {
    if (expanded && !initiated) initiate(); // eslint-disable-next-line
  }, [expanded]);

  React.useEffect(() => {
    if (initiated) updatePrice(); // eslint-disable-next-line
  }, [area]);

  const initiate = async () => {
    let obj = _.cloneDeep(productFrequency);
    const last3years = _.range(
      new Date().getFullYear() - 2,
      new Date().getFullYear() + 1
    );
    obj.years = last3years;
    obj.price = await calcPrice(last3years, obj.months);
    setProductFrequency({ ...obj });
    initiated = true;
  };

  const updatePrice = async () => {
    let obj = productFrequency;
    obj.price = await calcPrice(obj.years, obj.months);
    setProductFrequency({ ...obj });
  };

  const calcPrice = async (_years, _months) => {
    const totalYears = _years ? _years.length : 0;
    const totalMonths = Object.keys(_months).filter(v => _months[v]).length;

    const discountFactor = totalMonths === 12 ? 0.9 : 1;

    const min =
      10 * Math.pow(area.sqm, 0.36) * totalMonths * totalYears * discountFactor;
    const max = min * 1.3;

    if (max >= priceCap) return [priceCap, priceCap];
    else return [parseInt(min * 0.1) * 10, parseInt(max * 0.1) * 10];
  };

  return (
    <Box>
      <Typography variant="body2" paragraph>
        Surface water frequency data provides information about the frequency of
        water observations, at any given area of interest. Our surface water
        frequency maps are customized to your specific requirements for
        periodicity and seasonality. Use the panel below to select the years and
        months to be included.{' '}
        <span
          onClick={() => setDialogue(frequencyProduct)}
          className={classes.readMore}
        >
          Read more.
        </span>
      </Typography>
      <Typography variant="subtitle1">
        Get surface water frequency for period:
      </Typography>

      <YearPicker
        calcPrice={calcPrice}
        productFrequency={productFrequency}
        setProductFrequency={setProductFrequency}
      />
      <MonthPicker
        calcPrice={calcPrice}
        productFrequency={productFrequency}
        setProductFrequency={setProductFrequency}
      />
      {/* <Grid container spacing={0}>
        <Grid item xs={2}>
          <Typography
            onClick={() => resetProducts(productFrequency.id)}
            className={classes.clearDays}
            style={{ cursor: 'pointer' }}
          >
            Clear data
          </Typography>
        </Grid>

        <Grid item xs={10}></Grid>
      </Grid> */}
    </Box>
  );
}
