import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { AppContext } from 'App';

const GetQuoteButton = () => {
  const {
    actions: { setShowQuote },
    state: { sum },
  } = React.useContext(AppContext);

  return (
    <Box p={2}>
      <Button
        style={{ width: '100%' }}
        disabled={sum[0] === null || sum[0] === 0}
        onClick={() => setShowQuote(true)}
      >
        Get detailed quote and our advice
      </Button>
      <Box pt={1}></Box>
      <Typography variant="body2" style={{ color: '#86a2b3' }}>
        NB. by clicking the button above you will be directed to a confirmation
        page where you can provide your contact details and see the details of
        your request. When ready, click ‘Submit request’ to submit your request
        to our experts. We will get back to you within 24 hours with further
        information about prices and availability of your requested products.
      </Typography>
    </Box>
  );
};

export default GetQuoteButton;
