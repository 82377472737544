import React from 'react';
import DemoFloodExtent from './partials/DemoFloodExtent';
import DemoFloodFrequency from './partials/DemoFloodFrequency';
import DemoDEM from './partials/DemoDEM';
import DemoIntroduction from './partials/DemoIntroduction';
import DemoTabControl from './partials/DemoTabControl';
import DemoTitle from './partials/DemoTitle';
import LinkToFloodMetrics from './partials/LinkToFloodMetrics';
import { Paper } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import 'styles/scrollbar.css';

const DemoSidebar = ({ match }) => {
  const { path } = match;

  return (
    <Paper
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <DemoTitle />

      {/* Navbar with buttons */}
      <DemoTabControl match={match} />

      {/* { Components} */}
      <Switch>
        <Route exact path={`${path}/`} component={DemoIntroduction} />
        <Route
          path={`${path}/floodextent`}
          render={() => <DemoFloodExtent match={match} />}
        />
        <Route path={`${path}/dem`} render={() => <DemoDEM match={match} />} />
        <Route
          path={`${path}/floodfrequency`}
          render={() => <DemoFloodFrequency match={match} />}
        />
      </Switch>

      {/* Button component to Flood metrics */}
      <Route
        path={[`${path}/dem`, `${path}/floodextent`, `${path}/floodfrequency`]}
        component={LinkToFloodMetrics}
      />
    </Paper>
  );
};

export default DemoSidebar;
