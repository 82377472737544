import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

const italic = {
  fontStyle: 'italic',
};

const Testimonial = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper style={{ flexGrow: 1, overflow: 'hidden' }}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12} sm={3}>
            <Box py={1}>
              <Typography variant={'h4'} className={landingpage.productTitle}>
                Testimonial
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography variant={'body1'} style={italic} paragraph>
              "The Shoalhaven Local Government Area (LGA), along with many
              regions of Australia, experienced an unprecedented 2019 / 20
              bushfire season which resulted in approximately 75% of the
              Shoalhaven LGA being impacted by bushfire, including large areas
              of the Shoalhaven River catchment.
            </Typography>
            <Typography variant={'body1'} style={italic} paragraph>
              This was closely followed by a flood event in early February 2020
              which inundated a number of homes and resulted in large quantities
              of sediment, ash and bushfire debris being deposited along the
              banks of waterways, estuaries and the regions beaches.
            </Typography>
            <Typography variant={'body1'} style={italic} paragraph>
              Shoalhaven City Council became aware that DHI GRAS had already
              mapped the extent of the February 2020 Shoalhaven River flood
              event, using an innovative satellite-based approach.
            </Typography>
            <Typography variant={'body1'} style={italic} paragraph>
              Whilst the timing of satellite imagery missed the peak of this
              particular flood event, the ability to capture flood inundation
              extents over large areas using satellite based mapping would be
              beneficial to assist with the validation / calibration of existing
              hydraulic models (if the imagery roughly coincides with the
              recorded flood peak), to quickly identify areas to survey flood
              debris marks and if this data was available relatively quickly
              after a flood event it would assist in deploying resources to
              remove flood debris."
            </Typography>
            <Typography variant={'body1'} paragraph>
              Shoalhaven City Council Floodplain Unit
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Testimonial;
