import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import imageDEM from 'assets/images/product_dem.jpg';

const DEM = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper
      style={{ flexGrow: 1, overflow: 'hidden', backgroundColor: '#F2F5F7' }}
    >
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12} sm={6}>
            <Box py={1}>
              <Typography variant={'h4'} className={landingpage.productTitle}>
                Digital Elevation Model
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Updated and tailormade Digital Elevation Models (DEM), delivered
              in any ready-to-use GIS format, can be ordered through Flood
              Metrics.
            </Typography>
            <Box my={2}></Box>
            <Typography variant={'body1'}>
              The products are delivered by leading global service providers and
              created from stereoscopic satellite imagery, which is a highly
              cost-efficient alternative to traditional land and aerial based
              surveying approaches.
            </Typography>
            <Box my={2}></Box>
            <Typography variant={'body1'}>
              The product can be delivered as both a digital surface model (DSM)
              which includes surface features such as vegetation and man-made
              structures, as well as a bare-earth representation without above
              ground objects (DTM) – both options ranging from 1 m to 12 m in
              spatial resolution.
            </Typography>
            <Box my={1}>
              <img
                src={imageDEM}
                alt="flood extent"
                style={{ width: '100%' }}
              />
            </Box>
            <Typography style={{ textAlign: 'right', fontSize: 10 }}>
              Digital Elevation Model for region around Nissum Fjord, Denmark
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default DEM;
