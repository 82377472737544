import { makeStyles } from '@material-ui/core/styles';
import DHITheme from 'styles/theme';

const { primary } = DHITheme.palette;

export const checkboxStyle = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 1,
    width: 12,
    height: 12,
    border: '1px solid #86A2B3',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    borderRadius: 1,
    backgroundColor: '#61C051',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 12,
      height: 12,
      boxSizing: 'border-box',
      border: '1px solid #86A2B3',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#61C051',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(207,219,226)',
    },
  },
});

export const iconStyle = makeStyles({
  button: {
    width: 36,
    padding: 0,
    borderRadius: 0,
  },
  icon: {
    backgroundColor: '#fff',
    height: 36,
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
  activeIcon: {
    backgroundColor: '#0b4566',
    '& path': {
      fill: '#fff',
    },
    '&:hover': {
      backgroundColor: '#0b4566',
    },
  },
  disabledIcon: {
    '& path': {
      fill: '#cfdbe2',
    },
  },
});

export const productStyle = makeStyles({
  addDays: {
    fontSize: 10,
    textAlign: 'right',
    marginTop: 8,
    pointer: 'cursor',
    color: 'rgb(134, 162, 179)',
    '&:hover': {
      color: '#00A4EC',
    },
  },
  banner: {
    padding: ' 8px 16px',
    cursor: 'pointer',
    '& p': {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: ' #F2F5F7',
    },
  },
  clearDays: {
    fontSize: 10,
    marginTop: 16,
    color: 'rgb(134, 162, 179)',
    pointer: 'cursor',
    '&:hover': {
      color: '#FD3F75',
    },
  },
  container: {
    borderBottom: '1px solid #DBE4E9',
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 16,
    textAlign: 'right',
    color: 'rgb(134, 162, 179)',
  },
  popupLink: {
    color: `${primary.main}`,
    cursor: 'pointer',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  readMore: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  regionContainer: {
    backgroundColor: '#F2F5F7',
    borderTop: '1px solid #DBE4E9',
    borderBottom: '1px solid #DBE4E9',
    padding: '8px 16px',
  },
  regionTitleMain: {
    fontSize: 14,
    fontWeight: 500,
  },
  regionTitleArea: {
    fontSize: 10,
    margin: 0,
  },
  svg: {
    paddingLeft: 8,
    width: 24,
    height: 24,
  },
  totalPrice: {
    backgroundColor: '#F2F5F7',
    borderBottom: '1px solid #DBE4E9',
  },
  totalPriceTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
});

export const titleStyle = makeStyles({
  container: {
    padding: 16,
  },
  titleMain: {
    color: '#0b4566',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    padding: 0,
    margin: 0,
  },
  subTitle: {
    color: 'rgb(134, 162, 179)',
    fontSize: 12,
    padding: 0,
    margin: 0,
  },
});

export const productIconStyle = makeStyles({
  button: {
    width: '33.33%',
    borderRight: '1px solid #dbe4e9',
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '10px 0px 4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F5F7',
    },
    '& svg ': {
      stroke: '#0b4566',
    },
  },
  buttonSelected: {
    backgroundColor: '#0b4566',
    '&:hover': {
      backgroundColor: '#0b4566',
    },
    '& p': {
      color: '#fff',
    },
    '& svg ': {
      stroke: '#fff',
    },
  },
  title: {
    fontSize: 10,
    textAlign: 'center',
    color: '#86a2b3',
  },
});
