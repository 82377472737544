import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import LandingPage from './containers/Landingpage';
import IEnotification from './containers/IEnotification.js';
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, withStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'typeface-roboto';
import DHITheme from './styles/theme';
import App from './App';
import DateFnsUtils from '@date-io/date-fns';
import history from './data/history';

process.env.NODE_ENV === 'proudction' &&
  Sentry.init({
    dsn:
      'https://b052f6441fe94b4288d0cfed39ca9ba1@o377546.ingest.sentry.io/5210792',
  });

const CustomSnackbarProvider = withStyles({
  variantSuccess: { backgroundColor: '#61C051' },
  variantError: { backgroundColor: '#FD3F75' },
  variantWarning: { backgroundColor: '#FFC20A' },
  variantInfo: { backgroundColor: '#00A4EC' },
})(SnackbarProvider);

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

ReactDOM.render(
  <>
    {isIE ? (
      <IEnotification />
    ) : (
      <Router history={history}>
        <ThemeProvider theme={DHITheme}>
          <CustomSnackbarProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Switch>
                <Route exact path="/home">
                  <LandingPage />
                </Route>
                <Route path="/">
                  <App />
                </Route>
              </Switch>
            </MuiPickersUtilsProvider>
          </CustomSnackbarProvider>
        </ThemeProvider>
      </Router>
    )}
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
