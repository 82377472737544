import React from 'react';
import { ReactComponent as ZoomToAreaIcon } from '@mike/mike-shared-frontend/media/icons/ZoomToArea.svg';
import { IconButton, Grid, Tooltip } from '@material-ui/core';
import { iconStyle } from 'styles/global';
import { HomeContext } from 'Home';

const ZoomToAreaControl = () => {
  const {
    actions: { drawMode },
  } = React.useContext(HomeContext);

  const classes = iconStyle();

  return (
    <Grid
      container
      style={{ width: 36, boxShadow: 'rgba(0, 0, 0, 0.16) 4px 0px 4px' }}
    >
      <Grid container>
        <Tooltip placement="right" title={'Zoom to area'}>
          <IconButton
            className={classes.button}
            onClick={drawMode('rectangle')}
          >
            <ZoomToAreaIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ZoomToAreaControl;
