import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { ReactComponent as CloseXSIcon } from '@mike/mike-shared-frontend/media/icons/CloseXS.svg';
import { productStyle } from 'styles/global';

import { AppContext } from 'App';
import { HomeContext } from 'Home';

const ProductListHeader = () => {
  const {
    state: { area },
  } = React.useContext(AppContext);

  const {
    actions: { drawMode },
  } = React.useContext(HomeContext);

  const classes = productStyle();

  return (
    <Grid container spacing={0} className={classes.regionContainer}>
      <Grid item xs={11}>
        <Typography className={classes.regionTitleMain}>
          Product selection
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <CloseXSIcon
          style={{ paddingLeft: 16, cursor: 'pointer' }}
          onClick={drawMode('clear')}
        />
      </Grid>
      <Typography className={classes.regionTitleArea} variant="body2">
        {area && area.sqm && parseInt(area.sqm).toLocaleString() + ' km²'}
      </Typography>
    </Grid>
  );
};

export default ProductListHeader;
