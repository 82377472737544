import React from 'react';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FloodFrequency from '../ProductComponents/FloodFrequency';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { DemoContext } from 'Demo';
import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';

const DemoFloodFrequency = () => {
  const {
    state: { demoProductsFrequency, location },
    actions: { setLocation },
  } = React.useContext(DemoContext);

  const handleChange = i => {
    i === location ? setLocation() : setLocation(i);
  };

  return (
    <Box>
      <Typography variant="h6">
        Description of Surface water frequency
      </Typography>
      <Box px={2} pt={2} pb={0}>
        {defaultProducts[2].description}
      </Box>

      {demoProductsFrequency.locations &&
        demoProductsFrequency.locations.length > 0 && (
          <Typography variant="h6">
            Use cases for Surface water frequency
          </Typography>
        )}

      {demoProductsFrequency.locations &&
        demoProductsFrequency.locations.length > 0 &&
        demoProductsFrequency.locations.map(({ date, title }, i) => (
          <Accordion
            key={i}
            expanded={location === i}
            onChange={() => handleChange(i)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ width: 16, color: '#0B4566' }} />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid spacing={0} container>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontSize: 12 }}>
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <FloodFrequency date={date} title={title} />
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

export default DemoFloodFrequency;
