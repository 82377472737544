import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const linkStyle = makeStyles({
  button: {
    backgroundColor: '#fff',
    border: '2px solid #00A4EC',
    borderRadius: 4,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: 'rgba(0, 164, 236, 0.08)',
    },
  },
  link: {
    color: '#00A4EC',
    textDecoration: 'none',
  },
});

export default function DemoIntroduction() {
  const classes = linkStyle();

  return (
    <Box>
      <Typography variant="h6">
        Introduction to the demonstration site
      </Typography>
      <Box p={2}>
        <Typography variant="subtitle1">
          Welcome to the Flood Metrics demonstration site
        </Typography>
        <Typography variant="body2" paragraph>
          On this site you can visualise and explore examples of the data
          products offered through Flood Metrics.
        </Typography>
        <Typography variant="body2" paragraph>
          Click on the icons in the map to the left, or click on the product
          names above, to start exploring the demonstration data.
        </Typography>
      </Box>

      <Typography variant="h6">Find your own data</Typography>
      <Box p={2}>
        <Typography variant="body2" paragraph>
          If you are interested in data for your own region:
        </Typography>
        <Button className={classes.button}>
          <Link to={'/'} className={classes.link}>
            Visit flood metrics
          </Link>
        </Button>
      </Box>
    </Box>
  );
}
