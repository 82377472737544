import React from 'react';
import classnames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { DemoContext } from 'Demo';
import { makeStyles } from '@material-ui/core/styles';

import 'styles/scrollbar.css';

const useStyles = makeStyles(() => ({
  icon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    margin: '4px auto',
  },
  iconLink: {
    textDecoration: 'none',
  },
  iconLinkActive: {
    backgroundColor: '#F2F5F7',
  },
  iconTitle: {
    color: '#93C4D4',
    fontSize: 10,
  },
  iconWrapper: {
    textAlign: 'center',
    padding: '4px 0px',
    '&:hover': {
      backgroundColor: '#F2F5F7',
    },
  },
}));

const DemoTabControl = ({ match }) => {
  const {
    actions: { setLocation },
    state: { products },
  } = React.useContext(DemoContext);

  const classes = useStyles();
  const { pathname } = useLocation();
  const urlParam = pathname.split('/')[2];
  const orderedProducts = [products[1], products[0], products[2]];

  const handleChange = selection => {
    urlParam !== selection && setLocation();
  };

  return (
    <>
      <Typography variant="h6">Select your product category</Typography>
      <Grid container spacing={0}>
        {orderedProducts &&
          orderedProducts.map((prod, i) => (
            <Grid
              key={i}
              item
              xs={12 / products.length}
              className={classnames({
                [classes.iconWrapper]: true,
                [classes.iconLinkActive]: urlParam === prod.category,
              })}
            >
              <NavLink
                to={`${match.url}/${prod.category}`}
                className={classes.iconLink}
                onClick={() => handleChange(prod.category)}
              >
                <div
                  style={{ backgroundColor: prod.color }}
                  className={classes.icon}
                />
                <Typography className={classes.iconTitle}>
                  {prod.title}
                </Typography>
              </NavLink>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default DemoTabControl;
