import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { landingpageStyle } from 'styles/landingpage';
import BG from 'assets/images/flood_metrics_banner.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    background: `url(${BG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
  },
  description: {
    color: theme.palette.primary.main,
    fontSize: window.innerWidth < 600 ? 24 : 32,
    lineHeight: window.innerWidth < 600 && 1,
    fontWeight: 'bold',
  },
  title: {
    fontSize: window.innerWidth < 600 ? 60 : 120,
    lineHeight: window.innerWidth < 600 ? 0.8 : 0.7,
    paddingBottom: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

const Banner = () => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <div className={classes.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={0} style={{ padding: '20vh 0' }}>
          <Grid item xs={12}>
            <Typography className={classes.title}>DHI Flood Metrics</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              High-quality, cost-efficient and operational information for your
              flood monitoring needs
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Banner;
