import React from 'react';
import { Box, Typography } from '@material-ui/core';
import finalCheckmark from 'assets/images/finalCheckmark.svg';
import { landingpageStyle } from 'styles/landingpage';

const linkStyle = {
  color: '#00A4EC',
};

const textStyle = {
  color: '#0B4566',
};

const ContactConfirmation = () => {
  const landingpage = landingpageStyle();

  return (
    <Box py={2} style={{ textAlign: 'center' }}>
      <img
        src={finalCheckmark}
        alt="Confirmed!"
        style={{ width: 40, height: 40 }}
      />
      <Box pt={2}></Box>
      <Typography variant={'h4'} className={landingpage.h4}>
        Thanks!
      </Typography>
      <Typography variant={'body1'} gutterBottom style={textStyle}>
        One of our employees will get back to you as soon as possible.
      </Typography>
      <Typography variant={'body1'} gutterBottom style={textStyle}>
        If you want to find out more about DHI GRAS services, you can visit our
        website:{' '}
        <a
          href="https://www.dhi-gras.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          www.dhi-gras.com
        </a>
      </Typography>
    </Box>
  );
};

export default ContactConfirmation;
