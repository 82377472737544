import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import _ from 'lodash';
import Home from 'Home';
import Demo from 'Demo';

import DEMFloods from 'components/Sidebar/ProductList/DEMFloods';
import FloodExtent from 'components/Sidebar/ProductList/FloodExtent';
import FloodFrequency from 'components/Sidebar/ProductList/FloodFrequency';

export const AppContext = React.createContext();

const calcExtent1mtrLow = sqkm => 100 * Math.pow(sqkm, 0.51) + 20 * sqkm;
const calcExtent1mtrHigh = sqkm => 100 * Math.pow(sqkm, 0.51) + 30 * sqkm;

const calcExtent10mtrLow = sqkm => 84 * Math.pow(sqkm, 0.51);
const calcExtent10mtrHigh = sqkm => 84 * Math.pow(sqkm, 0.51) * 1.3;

const dateTemplate = {
  date: null,
  resolution: [
    {
      title: '1mtr',
      checked: false,
      formula: { low: calcExtent1mtrLow, high: calcExtent1mtrHigh },
    },
    {
      title: '10mtr',
      checked: true,
      formula: { low: calcExtent10mtrLow, high: calcExtent10mtrHigh },
    },
  ],
  price: [null, null],
};

const defaultExtent = {
  component: FloodExtent,
  id: 'productExtent',
  title: 'Flood Extent',
  dates: [dateTemplate],
  dateTemplate: _.cloneDeep(dateTemplate),
  price: [null, null],
  priceCap: 75000,
};

const defaultDEM = {
  component: DEMFloods,
  id: 'productDEM',
  title: 'Digital Elevation Model',
  resolution: null,
  price: [null, null],
  priceCap: 75000,
  resolutionRange: [
    {
      res: 1,
      sum: { min: null, max: null },
      sqmPrice: { min: 66.683, max: 90.932 },
      pow: 0.9509,
      checked: false,
    },
    {
      res: 4,
      sum: { min: null, max: null },
      sqmPrice: { min: 29.514, max: 53.125 },
      pow: 0.9666,
      checked: false,
    },
    {
      res: 8,
      sqmPrice: { min: 9.14, max: 17.138 },
      sum: { min: null, max: null },
      pow: 0.9747,
      checked: false,
    },
    {
      res: 12,
      sqmPrice: { min: 7.998, max: 12.568 },
      sum: { min: null, max: null },
      pow: 0.9747,
      checked: false,
    },
  ],
};

const defaultFrequency = {
  component: FloodFrequency,
  id: 'productFrequency',
  title: 'Surface Water Frequency',
  months: {
    Jan: true,
    Feb: true,
    Mar: true,
    Apr: true,
    May: true,
    Jun: true,
    Jul: true,
    Aug: true,
    Sep: true,
    Oct: true,
    Nov: true,
    Dec: true,
  },
  priceCap: 75000,
  price: [null, null],
  years: null,
  yearRange: _.range(1997, new Date().getFullYear() + 1),
};

const App = () => {
  const [area, setArea] = useState({ coordinates: null, sqm: null });
  const [productExtent, setProductExtent] = useState(
    _.cloneDeep(defaultExtent)
  );
  const [productDEM, setProductDEM] = useState(_.cloneDeep(defaultDEM));
  const [productFrequency, setProductFrequency] = useState(
    _.cloneDeep(defaultFrequency)
  );

  const [openDrawer, setOpenDrawer] = useState(true);
  const [sidebarComponent, setSidebarComponent] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const [sum, setSum] = useState([null, null]);

  React.useEffect(() => {
    console.log('# react app environment:', process.env.NODE_ENV);
    window.dispatchEvent(new Event('resize'));
  }, [openDrawer]);

  React.useEffect(() => {
    let min = 0;
    let max = 0;

    min += productDEM.price[0] !== null ? productDEM.price[0] : 0;
    max += productDEM.price[1] !== null ? productDEM.price[1] : 0;

    min += productExtent.price[0] !== null ? productExtent.price[0] : 0;
    max += productExtent.price[1] !== null ? productExtent.price[1] : 0;

    min += productFrequency.price[0] !== null ? productFrequency.price[0] : 0;
    max += productFrequency.price[1] !== null ? productFrequency.price[1] : 0;

    setSum([min, max]);
  }, [productDEM, productExtent, productFrequency]);

  const resetProducts = id => {
    if (id === 'all') {
      setProductExtent(_.cloneDeep(defaultExtent));
      setProductDEM(_.cloneDeep(defaultDEM));
      setProductFrequency(_.cloneDeep(defaultFrequency));
      setArea({ coordinates: null, sqm: null });
    }
    if (id === 'productExtent') setProductExtent(_.cloneDeep(defaultExtent));
    if (id === 'productDEM') setProductDEM(_.cloneDeep(defaultDEM));
    if (id === 'productFrequency')
      setProductFrequency(_.cloneDeep(defaultFrequency));
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          area,
          openDrawer,
          productDEM,
          productExtent,
          productFrequency,
          showConfirmation,
          showQuote,
          sidebarComponent,
          sum,
        },
        actions: {
          resetProducts,
          setArea,
          setOpenDrawer,
          setProductDEM,
          setProductExtent,
          setProductFrequency,
          setShowConfirmation,
          setShowQuote,
          setSidebarComponent,
        },
      }}
    >
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/showcase" component={Demo} />
      </Switch>
    </AppContext.Provider>
  );
};

export default App;
