import React from 'react';
import {  Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const buttonStyle = makeStyles({
  buttonSecondary: {
    backgroundColor: '#fff',
    border: '2px solid #00A4EC ',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(0, 164, 236, 0.08)',
    },
  },
  link: {
    color: '#00A4EC',
    fontWeight: 700,
    textDecoration: 'none',
  },
});

export default function DemonstrationData() {
  const classes = buttonStyle();

  return (
    <Box>
      <Typography variant="h6">Demonstration data</Typography>
      <Box p={2}>
        <Typography variant="subtitle1" paragraph>
          Explore examples of the 3 data products
        </Typography>
        <Typography variant="body2" paragraph>
          Click the button below to access an interactive data viewer to explore
          examples of flood extent, surface water frequency and digital
          elevation data.
        </Typography>
        <Typography variant="body2">
          <Button className={classes.buttonSecondary}>
            <Link to={'/showcase'} className={classes.link}>
              Visit online demonstration
            </Link>
          </Button>
        </Typography>
      </Box>
    </Box>
  );
}
