import { useState, useEffect } from 'react';
import area from '@turf/area';

import axios from 'axios';
import * as Sentry from '@sentry/browser';

export const polygonArea = geojson => {
  return area(geojson) / 1000 ** 2;
};

function getWindowDimensions() {
  const { clientWidth: width, clientHeight: height } = document.body;
  return {
    width,
    height,
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const apiBaseUrl =
  'https://dna-sendmail.azurewebsites.net/api/sendmail';

export const sendMail = async ({ body }) => {
  // console.log(body);
  try {
    const endpoint = apiBaseUrl;
    const response = await axios.post(endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    Sentry.captureMessage(error);
    return Promise.reject(error);
  }
};

export const kazakhstanColormap = {
  '0': 'F3FBEE',
  '1': 'F3FBEE',
  '2': 'F1F9EB',
  '3': 'EDF8E7',
  '4': 'EDF8E7',
  '5': 'EBF6E5',
  '6': 'E9F6E3',
  '7': 'E7F6E1',
  '8': 'E6F5E0',
  '9': 'E5F4DF',
  '10': 'E1F4DB',
  '11': 'DFF3DA',
  '12': 'DFF3DA',
  '13': 'DEF2D9',
  '14': 'DAF1D5',
  '15': 'D9F0D4',
  '16': 'D8EFD3',
  '17': 'D7EED2',
  '18': 'D5EED0',
  '19': 'D4EDCF',
  '20': 'D3ECCE',
  '21': 'D1ECCB',
  '22': 'CEECC8',
  '23': 'CDEBC7',
  '24': 'CCEAC6',
  '25': 'CAEBC6',
  '26': 'C8E8C3',
  '27': 'C4E6C1',
  '28': 'C2E6C0',
  '29': 'BFE5C0',
  '30': 'BBE3BE',
  '31': 'B9E3BD',
  '32': 'B6E3BC',
  '33': 'B2E0BB',
  '34': 'B0E0BA',
  '35': 'ACDEB8',
  '36': 'AADDB6',
  '37': 'A6DCB5',
  '38': 'A3DBB8',
  '39': 'A1DABA',
  '40': '9DD8B8',
  '41': '9AD7B8',
  '42': '95D5BA',
  '43': '91D5BC',
  '44': '8ED3BE',
  '45': '89D0BE',
  '46': '87CFC1',
  '47': '84CFC2',
  '48': '80CEC0',
  '49': '7DCDC2',
  '50': '79CAC3',
  '51': '76C9C5',
  '52': '71C7C6',
  '53': '6EC6C8',
  '54': '69C2C8',
  '55': '66C0C9',
  '56': '65BFCA',
  '57': '5FBCCB',
  '58': '5EBBCD',
  '59': '58B8CE',
  '60': '55B7D0',
  '61': '51B4D1',
  '62': '4EB2D2',
  '63': '4BAFCF',
  '64': '49ADCF',
  '65': '46AACE',
  '66': '43A6CC',
  '67': '40A3CA',
  '68': '3DA0C9',
  '69': '3B9EC8',
  '70': '3999C4',
  '71': '3697C4',
  '72': '3494C4',
  '73': '3191C3',
  '74': '2E8EC0',
  '75': '2B8BBE',
  '76': '2988BE',
  '77': '2584BA',
  '78': '2182B8',
  '79': '1F80B5',
  '80': '1D7DB7',
  '81': '1B7BB5',
  '82': '1777B3',
  '83': '1473B3',
  '84': '1271B1',
  '85': '0F6EAE',
  '86': '0D6CAC',
  '87': '0A69AB',
  '88': '0867AB',
  '89': '0764A8',
  '90': '0760A3',
  '91': '0A5D9F',
  '92': '095A9C',
  '93': '07569A',
  '94': '095497',
  '95': '095092',
  '96': '084D8E',
  '97': '08498D',
  '98': '0A4788',
  '99': '0A4386',
  '100': '0A4386',
};
