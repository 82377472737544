import React from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const linkStyle = makeStyles({
  button: {
    backgroundColor: '#fff',
    border: '2px solid #00A4EC',
    borderRadius: 4,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: 'rgba(0, 164, 236, 0.08)',
    },
  },
  link: {
    color: '#00A4EC',
    textDecoration: 'none',
  },
});

const LinkToFloodMetrics = () => {
  const classes = linkStyle();
  return (
    <Box>
      <Typography variant="h6">Find your own data</Typography>
      <Box px={2} py={1}>
        <Typography variant="body2" style={{ marginBottom: 0 }}>
          If you are interested in data for your own region:
        </Typography>
        <Box pt={1}>
          <Button className={classes.button}>
            <Link to={'/'} className={classes.link}>
              Visit flood metrics
            </Link>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LinkToFloodMetrics;
