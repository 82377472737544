import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

const About = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={landingpage.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              DHI’s novel image processing methods, advanced machine learning
              algorithms and automated processing workflows of best in class
              satellite imagery fuels a new generation of operational flood
              monitoring products.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Flood Metrics is your all-in-one interface for DHI’s
              state-of-the-art satellite-based flood monitoring products,
              allowing you to search, request and purchase satellite-based flood
              products and Digital Elevation Models (DEM) from a single source.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default About;
