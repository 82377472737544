import React from 'react';
import _ from 'lodash';
import VolumeDisclaimerAccordion from '../VolumeDisclaimers/VolumeDisclaimerAccordion';
import { Box, Checkbox, Grid, Typography } from '@material-ui/core';
import { AppContext } from 'App';
import { checkboxStyle } from 'styles/global';
import DHITheme from 'styles/theme';

const { primary } = DHITheme.palette;
const priceStyle = {
  textAlign: 'right',
  color: `${primary.main}`,
};

var initiated = false;

export default function DEMCheckboxes({ expanded }) {
  const {
    state: { area, productDEM },
    actions: { setProductDEM },
  } = React.useContext(AppContext);

  const { priceCap, resolutionRange } = productDEM;
  const classes = checkboxStyle();

  React.useEffect(() => {
    if (expanded && !initiated) initiate(); // eslint-disable-next-line
  }, [expanded]);

  const initiate = async () => {
    let obj = _.cloneDeep(productDEM);
    obj.resolutionRange[3].checked = true;
    obj.resolutionRange[3].sum = await compPrice(obj.resolutionRange[3]);
    setProductDEM({ ...obj });
    initiated = true;
  };

  React.useEffect(() => {
    area.sqm && recalculatePrices(); // eslint-disable-next-line
  }, [area.sqm]);

  const recalculatePrices = async () => {
    let obj = _.cloneDeep(productDEM);

    for (let i = 0; i < obj.resolutionRange.length; i++) {
      if (obj.resolutionRange[i].checked)
        obj.resolutionRange[i].sum = await compPrice(obj.resolutionRange[i]);
    }
    setProductDEM({ ...obj });
  };

  const handleChange = async i => {
    let obj = _.cloneDeep(productDEM);

    obj.resolutionRange[i].checked = !obj.resolutionRange[i].checked;

    if (obj.resolutionRange[i].checked)
      obj.resolutionRange[i].sum = await compPrice(obj.resolutionRange[i]);
    else obj.resolutionRange[i].sum = { min: null, max: null };

    setProductDEM({ ...obj });
  };

  const compPrice = async obj => {
    let min = obj.sqmPrice.min * Math.pow(area.sqm, obj.pow);
    let max = obj.sqmPrice.max * Math.pow(area.sqm, obj.pow);

    if (min >= priceCap) min = priceCap;
    if (max >= priceCap) max = priceCap;

    return { min: parseInt(min * 0.1) * 10, max: parseInt(max * 0.1) * 10 };
  };

  return (
    <Box>
      {resolutionRange &&
        resolutionRange.map(({ checked, res, sum }, i) => (
          <Box
            key={i}
            width={1}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChange(i)}
          >
            <Grid container style={{ padding: '2px 0px' }}>
              <Grid item xs={1}>
                <Checkbox
                  checked={checked}
                  checkedIcon={
                    <span className={([classes.icon], [classes.checkedIcon])} />
                  }
                  icon={<span className={[classes.icon]} />}
                  style={{ width: 16, height: 16 }}
                />
              </Grid>
              <Grid item xs={3} style={{ paddingTop: 4 }}>
                <Typography
                  variant="body2"
                  style={{ color: `${primary.main}` }}
                >
                  {res + ' meter'}
                </Typography>
              </Grid>
              <Grid item xs={8} style={{ paddingTop: 4 }}>
                {checked && sum.max >= priceCap ? (
                  <VolumeDisclaimerAccordion />
                ) : (
                  <Typography variant="body2" style={priceStyle}>
                    {checked && sum.max && sum.max < priceCap
                      ? `€${sum.min.toLocaleString()} - €${sum.max.toLocaleString()}`
                      : ' -'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
    </Box>
  );
}
