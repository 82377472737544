import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { defaultProducts } from 'components/Sidebar/Info/defaultProducts';
import { productStyle } from 'styles/global';
import { HomeContext } from 'Home';

const dialogueLinks = [
  { title: 'Flood extent', product: defaultProducts[0] },
  { title: 'Digital Elevation Models', product: defaultProducts[1] },
  { title: 'Surface water frequency ', product: defaultProducts[2] },
];

const About = () => {
  const {
    actions: { setDialogue },
  } = React.useContext(HomeContext);

  const styles = productStyle();

  return (
    <Box>
      <Typography variant="subtitle1">About Flood Metrics</Typography>
      <Typography variant="body2" paragraph>
        Flood Metrics is your all-in-one interface for DHI’s state-of-the-art
        satellite-based flood monitoring products, allowing you to search,
        request and purchase our novel satellite-based flood products and
        Digital Elevation Models (DEM) from a single source.
      </Typography>
      <Typography variant="body2" paragraph>
        Flood Metrics provides instant price range estimates for 3 different
        products (
        {dialogueLinks.map(({ title, product }, i) => (
          <React.Fragment key={i}>
            <span
              onClick={() => setDialogue(product)}
              className={styles.popupLink}
            >
              {title}
            </span>
            {i === 0 ? ', ' : i === 1 ? ' and ' : ''}
          </React.Fragment>
        ))}
        ), allowing you to quickly assess costs for your specific area of
        interest. The prices are individually tailored and customized to your
        specific product requirements in terms of spatial resolution, date
        range(s) and other relevant parameters.
      </Typography>
      <Typography variant="subtitle1">How to use it</Typography>
      <Typography variant="body2" paragraph>
        Using Flood Metrics is as easy as 1-2-3:
      </Typography>
      <Typography variant="body2" paragraph>
        <span style={{ fontWeight: 600 }}>
          1) Define your area of interest:{' '}
        </span>
        Click the ‘Start Drawing’ button below to draw a polygon outlining the
        boundaries of your area of interest.
      </Typography>
      <Typography variant="body2" paragraph>
        <span style={{ fontWeight: 600 }}>2) Select your products: </span>
        Select your products and define your specific product requirements to
        retrieve an instant price estimate.
      </Typography>
      <Typography variant="body2" paragraph>
        <span style={{ fontWeight: 600 }}>3) Get detailed quote: </span>
        Click the ‘Get detailed quote’ button to send a request for more
        information on the exact costs and availability of your selected
        products.”
      </Typography>

      <Typography variant="subtitle1">About DHI</Typography>
      <Typography variant="body2">
        DHI are the first people you should call when you have a tough challenge
        to solve in a water environment – be it a river, a reservoir, an ocean,
        a coastline, or an aquifer. Our knowledge of water environments is
        second-to-none. It represents 50 years of dedicated research and
        real-life experience from more than 140 countries. We strive to make
        this knowledge globally accessible to clients and partners by
        channelling it through our local teams and unique software. Our world is
        water. So whether you need to save water, share it fairly, improve its
        quality, quantify its impact or manage its flow, we can help. Our
        knowledge, combined with our team's expertise and the power of our
        technology, holds the key to unlocking the right solution. Read more on
        <Link href="https://www.dhigroup.com/"> dhigroup.com</Link>
      </Typography>
    </Box>
  );
};

export default About;
