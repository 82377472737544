import React from 'react';
import { Box, Typography } from '@material-ui/core';
import FloodFrequencyLegend from 'assets/images/flood_frequency_legend.jpg';

const legendRange = ['0%', '20%', '40%', '60%', '80%', '100%'];
const colorbarStyle = {
  width: '100%',
  height: 8,
  borderRadius: 4,
};

const tableStyle = {
  width: '100%',
  fontSize: 10,
  textAlign: 'center',
};

const FloodFrequency = ({ title, date }) => {
  return (
    <Box display="block">
      <Typography variant="subtitle1">
        Surface water frequency for {title} during 2016 - 2020
      </Typography>

      <img src={FloodFrequencyLegend} alt="" style={colorbarStyle} />

      {legendRange && (
        <table style={tableStyle}>
          <tbody>
            <tr>
              {legendRange.map(v => (
                <td key={v}>{v}</td>
              ))}
            </tr>
          </tbody>
        </table>
      )}
    </Box>
  );
};

export default FloodFrequency;
