import React from 'react';
import Navbar from './partials/Navbar';
import About from './partials/About';
import Banner from './partials/Banner';
import BulletPoints from './partials/BulletPoints';
import Contact from './partials/Contact';
import ContactForm from './partials/ContactForm';
import DEM from './partials/DEM';
import FloodExtent from './partials/FloodExtent';
import FloodFrequency from './partials/FloodFrequency';
import Intro from './partials/Intro';
import ProductOverview from './partials/ProductOverview';
import Testimonial from './partials/Testimonial';
import UseCases from './partials/UseCases';
import Visit from './partials/Visit';

import formatISO from 'date-fns/formatISO';
import * as Sentry from '@sentry/browser';
import { sendMail } from 'data/utils';
import { useWindowDimensions } from 'data/utils';

const LandingPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [contactConfirmed, setConfirmed] = React.useState();
  const { width } = useWindowDimensions();
  const padding = width > 600 ? '12.5vh 0' : '20vh 0';

  const handleSendMail = async details => {
    try {
      setLoading(true);
      const response = await sendMail({
        body: {
          ...details,
          application: 'floodmetrics',
          request_date: formatISO(new Date(Date.now())),
        },
      });
      if (!response) throw Error(`Request rejected`);
      setLoading(false);
      setConfirmed(true);
    } catch (error) {
      setLoading(false);
      setConfirmed(false);
      Sentry.captureMessage(error);
      console.error(error);
    }
  };

  return (
    <>
      <Navbar />
      <Banner />
      <Intro padding={padding} />
      <BulletPoints padding={padding} />
      <ProductOverview padding={padding} />
      <FloodExtent padding={padding} />
      <FloodFrequency padding={padding} />
      <DEM padding={padding} />
      <UseCases padding={padding} />
      <Visit padding={padding} />
      <Testimonial padding={padding} />
      <ContactForm
        contactConfirmed={contactConfirmed}
        handleSendMail={handleSendMail}
        loading={loading}
        padding={padding}
        setLoading={setLoading}
      />

      <About padding={padding} />
      <Contact />
    </>
  );
};

export default LandingPage;
