import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { AppContext } from 'App';

export default function AreaDetails() {
  const {
    state: { area, sum },
  } = React.useContext(AppContext);

  const hardcodedPriceCap = 75000;

  return (
    <>
      {sum && sum[1] && (
        <>
          <Typography variant="subtitle1">Total price range:</Typography>
          <Typography variant="body2" style={{ margin: 0 }}>
            {sum[0] >= hardcodedPriceCap
              ? 'Price: Volume discount applies'
              : `Price range: €${sum[0].toLocaleString()} - €${sum[1].toLocaleString()}`}
          </Typography>
        </>
      )}
      {area && area.sqm && (
        <Paper>
          <Typography variant="subtitle1" style={{ marginTop: 16 }}>
            Area in km²:
          </Typography>
          <Typography variant="body2" style={{ margin: 0 }}>
            {parseInt(area.sqm).toLocaleString() + ' km²'}
          </Typography>
        </Paper>
      )}
    </>
  );
}
