import { makeStyles } from '@material-ui/core/styles';

export const landingpageStyle = makeStyles(theme => ({
  landingPage: {
    maxWidth: 1080,
    padding: '20vh, 0',
    media: {
      width: 540,
      height: 320,
    },
  },
  box: {
    maxWidth: 1080,
    backgroundColor: 'transparent',
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
    color: '#0B4566',
  },
  icon: {
    width: 32,
    height: 32,
    '& path': {
      fill: theme.palette.secondary.main,
    },
  },
  rootGrey: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: '#F2F5F7 !important',
  },
  productTitle: {
    color: '#00A4EC',
    fontSize: 32,
    fontWeight: 700,
  },
}));
