import React from 'react';
import { Grid, Link } from '@material-ui/core';
import DHILogo from 'assets/images/dhi_logo_white.png';

const linkStyle = {
  color: '#fff',
  fontSize: 14,
  fontWeight: 500,
  paddingTop: 10,
};

const Navbar = () => {
  return (
    <Grid
      container
      spacing={0}
      style={{ backgroundColor: '#00A4EC', padding: '8px 32px' }}
    >
      <Grid item xs={6} sm={2}>
        <img src={DHILogo} alt="DHI Logo" />
      </Grid>

      <Grid item xs={6} sm={10}>
        <Grid
          container
          alignItems="flex-end"
          justify="flex-end"
          direction="row"
        >
          <Link href="/" style={linkStyle}>
            Visit viewer
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navbar;
