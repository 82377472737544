import React from 'react';
import { Typography } from '@material-ui/core';

import iconDEM from 'assets/images/icon_dem.png';
import iconExtent from 'assets/images/icon_extent.png';
import iconFrequency from 'assets/images/icon_frequency.png';

import imageDEM from 'assets/images/product_dem.jpg';
import imageExtent from 'assets/images/product_flood_extent.png';
import imageFrequency from 'assets/images/product_flood_frequency.jpg';

const defaultProducts = [
  {
    id: 'floodExtent',
    icon: iconExtent,
    title: 'Flood extent',
    image: imageExtent,
    link: 'floodextent',
    media: (
      <img src={imageExtent} alt={'floodextent'} style={{ width: '100%' }} />
    ),
    active: true,
    svg: (
      <svg style={{ fill: 'none' }} viewBox="0 0 90 17">
        <polygon points="36.39 3.5 28.39 9.75 27.39 13.32 33.39 16 41.39 13.32 48.39 15.11 53.39 10.64 53.39 7.07 48.39 3.5 36.39 3.5" />
      </svg>
    ),
    description: (
      <>
        <Typography variant={'body2'} paragraph>
          The latest machine learning technology and advanced image analysis is
          used to accurately map the spatial extent of flooding’s on a given
          day.
        </Typography>
        <Typography variant={'body2'} paragraph>
          By fusing both optical and radar satellite data, we map flooded areas
          with high precision, and regardless of weather conditions, cloud cover
          and time of day.
        </Typography>
        <Typography variant={'body2'} paragraph>
          Our optimized and automated processing chains ensures fast,
          consistent, reliable and timely delivery of flood extent products. The
          product is available at any location, globally, and can be delivered
          in near-real time for ongoing flooding events or any historical
          flooding in the last 25 years (depending on data availability).
        </Typography>
      </>
    ),
  },
  {
    id: 'DEM4Floods',
    icon: iconDEM,
    title: 'Digital Elevation Model',
    active: false,
    image: imageDEM,
    link: 'dem',
    media: (
      <img
        src={imageDEM}
        alt={'Digital Elevation Model'}
        style={{ width: '100%' }}
      />
    ),
    svg: (
      <svg style={{ fill: 'none' }} viewBox="0 0 90 17">
        <polyline points="37.64 12.43 44.98 2.83 53.14 11.74" />
        <polyline points="27.64 15.32 36.98 2.83 41.64 7.33" />
      </svg>
    ),
    description: (
      <>
        <Typography variant="body2" paragraph>
          Up to date and tailormade Digital Elevation Models (DEM), delivered in
          any ready-to-use GIS format, can be ordered through Flood Metrics.
        </Typography>
        <Typography variant="body2" paragraph>
          The products are delivered by leading global service providers and
          created from stereoscopic satellite imagery, which is a highly
          cost-efficient alternative to traditional land and aerial based
          surveying approaches.
        </Typography>
        <Typography variant="body2" paragraph>
          The product can be delivered as both a digital surface model (DSM)
          which includes surface features such as vegetation and man-made
          structures, as well as a bare-earth representation without above
          ground objects (DTM) – both options ranging from 1 m to 12 m in
          spatial resolution.
        </Typography>
      </>
    ),
  },
  {
    id: 'FloodFrequency',
    icon: iconFrequency,
    title: 'Surface Water Frequency',
    image: imageFrequency,
    link: 'floodfrequency',
    media: (
      <img
        src={imageFrequency}
        alt={'floodfrequency'}
        style={{ width: '100%' }}
      />
    ),
    active: false,
    svg: (
      <svg style={{ fill: 'none' }} viewBox="0 0 90 17">
        <path d="M64.77,8.31c-1.11-.08-1.66-1.56-2.21-3s-1.11-2.78-2.22-2.71-1.66,1.42-2.21,2.71S57,7.85,55.91,7.77,54.25,6.48,53.7,5.34s-1.11-2.25-2.22-2.17-1.66,1.15-2.22,2.17-1.1,2-2.21,1.9-1.67-1-2.22-1.9S43.72,3.63,42.61,3.7,41,4.59,40.4,5.34,39.29,6.78,38.18,6.7A3.11,3.11,0,0,1,36,5.34a2.51,2.51,0,0,0-2.22-1.1,3.43,3.43,0,0,0-2.22,1.1,2.72,2.72,0,0,1-2.21.83,4.26,4.26,0,0,1-2.22-.83,3.21,3.21,0,0,0-2.22-.57,6.31,6.31,0,0,0-2.22.57,4.57,4.57,0,0,1-2.22.29c-1.11-.07-1.66-.22-2.22-.29a9,9,0,0,0-2.22,0" />
      </svg>
    ),
    description: (
      <>
        <Typography variant="body2" paragraph>
          State-of-the-art machine learning algorithms and optimised processing
          workflows allows for seamless analysis of long time-series of water
          extent data.
        </Typography>
        <Typography variant="body2" paragraph>
          This is used to generate our surface water frequency product, which
          provides information about the frequency of water observations, at any
          given area of interest.
        </Typography>
        <Typography variant="body2" paragraph>
          The product is available at any location, globally, and is tailored to
          specific client needs to provide information about occurrence rates
          for specific months, seasons or years.
        </Typography>
      </>
    ),
  },
  // {
  //   id: 'eventImagery',
  //   icon: 'imagery',
  //   title: 'Event imagery',
  //   active: false,
  //   imgPath: 'event_imagery.jpg',
  //   svg: (
  //     <svg style={{ fill: 'none' }} viewBox="0 0 90 17">
  //       <rect x="30.9" y="3.63" width="18.97" height="12.14" />
  //     </svg>
  //   ),
  //   description: (
  //     <>
  //       <Typography variant={'body2'}>
  //         Satellites pass over regularly and frequently. But did you know that
  //         you can order a satellite to be at your location at your desired time?
  //       </Typography>
  //       <Typography variant={'body2'}>
  //         In case of future flooding events, you can preorder a satellite to
  //         your location and observe and assess your situation from the sky.
  //       </Typography>
  //       <Typography variant={'body2'}>
  //         This will provide valuable information for instructing your safety
  //         teams and water management operations
  //       </Typography>
  //       ""
  //       <Typography variant={'body2'}>
  //         This data is not available through this web viewer, but feel free to
  //         contact us and we will take care of the rest.
  //       </Typography>
  //     </>
  //   ),
  // },
];

export { defaultProducts };
