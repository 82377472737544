import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import AreaDetails from './AreaDetails';
import ContactDetails from './ContactDetails';
import DemDetails from './DemDetails';
import FloodExtentDetails from './FloodExtentDetails';
import FloodFrequencyDetails from './FloodFrequencyDetails';

import { productIconStyle } from 'styles/global';
import { productStyle } from 'styles/global';
import { AppContext } from 'App';

const QuoteDetails = () => {
  const {
    actions: { setShowQuote },
    state: { productExtent, productDEM, productFrequency },
  } = React.useContext(AppContext);

  const classes = productStyle();
  const styles = productIconStyle();

  return (
    <Box>
      <Grid container spacing={0} className={classes.regionContainer}>
        <Grid item xs={10}>
          <Typography className={classes.regionTitleMain}>
            Product overview
          </Typography>
        </Grid>
        <Grid
          item
          xs
          onClick={() => setShowQuote(false)}
          style={{ cursor: 'pointer', textAlign: 'right', paddingTop: 4 }}
        >
          <Typography className={styles.title}>Go back</Typography>
        </Grid>
      </Grid>
      <Box m={2}>
        <AreaDetails />
        {productExtent.price[0] !== null && <FloodExtentDetails />}
        {productDEM.price[0] !== null && <DemDetails />}
        {productFrequency.price[0] !== null && <FloodFrequencyDetails />}
      </Box>
      <Box>
        <ContactDetails />
      </Box>
    </Box>
  );
};

export default QuoteDetails;
