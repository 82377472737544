import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

import { AppContext } from 'App';
import { HomeContext } from 'Home';
import finalCheckmark from 'assets/images/finalCheckmark.svg';

const buttonStyle = makeStyles({
  buttonMain: {
    width: '100%',
    backgroundColor: '#00A4EC',
    '&:hover': {
      backgroundColor: '#008BEC',
    },
  },
  buttonSecondary: {
    width: '50%',
    backgroundColor: '#fff',
    border: '2px solid #00A4EC ',
    boxSizing: 'border-box',
    color: '#00A4EC',
    '&:hover': {
      backgroundColor: 'rgba(0, 164, 236, 0.08)',
    },
  },
  link: {
    color: '#00A4EC',
    fontSize: 12,
    fontWeight: 700,
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

export default function Confirmation() {
  const {
    actions: { drawMode, setShowConfirmation },
  } = React.useContext(HomeContext);

  const {
    state: { productDEM, productExtent, productFrequency },
  } = React.useContext(AppContext);

  const classes = buttonStyle();

  const products = [productDEM, productExtent, productFrequency];

  const selectedProducts = products
    .filter(prod => prod.price[0] !== null && prod.price[0] !== 0)
    .map(prod => prod.title);

  const productString = selectedProducts
    .join(', ')
    .replace(/,(?!.*,)/gim, ' and');

  const reset = () => {
    drawMode('clear')();
    setShowConfirmation(false);
  };

  return (
    <Box>
      <Typography variant="h6">Confirmation</Typography>
      <Box px={2}>
        <Box py={2} style={{ textAlign: 'center' }}>
          <img
            src={finalCheckmark}
            alt="Confirmed!"
            style={{ width: 40, height: 40 }}
          />
        </Box>
        <Typography variant="subtitle1">Thank you for your request!</Typography>
        <Typography variant="body2">
          We appreciate you contacting us about {productString} data in your
          area of interest. One of our flood mapping specialists will process
          your request and get back to you shortly with further information
          about data availability and delivery timing.
        </Typography>
        <Typography variant="body2" style={{ marginBottom: 4 }}>
          In the meantime, you can check out the following resources for further
          information:
        </Typography>
        <a
          href="https://www.dhi-gras.com/news-room/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          Latest news
        </a>
        <Box pt={2}></Box>
        <Typography variant="body2">Have a great day!</Typography>
        <p className={classes.link} onClick={() => reset()}>
          Back to start
        </p>
      </Box>
    </Box>
  );
}
