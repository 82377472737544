import React from 'react';
import Typography from '@material-ui/core/Typography';

import { HomeContext } from 'Home';
import { makeStyles } from '@material-ui/core';

const volumeDisclaimer = {
  title: 'Volume discount applies, contact us for pricing',
  description: (
    <Typography variant="body2" paragraph>
      The product selected for your area of interest qualifies for a volume
      discount. Please proceed with your quote request to receive a detailed
      quote and our expert advice on preferred product options for your selected
      area. We will respond to your request within 24 hours
    </Typography>
  ),
};

const volumeDisclaimerStyle = makeStyles({
  link: {
    textAlign: 'right',
    fontSize: 12,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const VolumeDisclaimerTitle = () => {
  const {
    actions: { setDialogue },
  } = React.useContext(HomeContext);

  const classes = volumeDisclaimerStyle();

  const handleClick = e => {
    e.stopPropagation();
    setDialogue(volumeDisclaimer);
  };

  return (
    <Typography variant="h5" className={classes.link} onClick={handleClick}>
      Volume discount applies
    </Typography>
  );
};

export default VolumeDisclaimerTitle;
