import React from 'react';
import { Paper, IconButton } from '@material-ui/core';
import { iconStyle } from 'styles/global';
import { ReactComponent as LeftIcon } from '@mike/mike-shared-frontend/media/icons/Left.svg';
import { ReactComponent as RightIcon } from '@mike/mike-shared-frontend/media/icons/Right.svg';
import { ReactComponent as UpIcon } from '@mike/mike-shared-frontend/media/icons/Up.svg';
import { ReactComponent as DownIcon } from '@mike/mike-shared-frontend/media/icons/Down.svg';
import { DemoContext } from 'Demo';

const DemoSidebarControl = ({ mobile }) => {
  const {
    actions: { setOpenDrawer },
    state: { openDrawer },
  } = React.useContext(DemoContext);

  const classes = iconStyle();

  return (
    <Paper
      style={{
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <IconButton
        className={classes.button}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        {openDrawer === true && mobile !== true && (
          <RightIcon className={classes.icon} />
        )}
        {openDrawer === false && mobile !== true && (
          <LeftIcon className={classes.icon} />
        )}
        {openDrawer === true && mobile === true && (
          <DownIcon className={classes.icon} />
        )}
        {openDrawer === false && mobile === true && (
          <UpIcon className={classes.icon} />
        )}
      </IconButton>
    </Paper>
  );
};

export default DemoSidebarControl;
