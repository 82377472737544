import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import imageFloodFrequency from 'assets/images/product_flood_frequency.jpg';

const FloodFrequency = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper style={{ flexGrow: 1, overflow: 'hidden', backgroundColor: '#fff' }}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: padding }}>
          <Grid item xs={12} sm={6}>
            <Box py={1}>
              <Typography variant={'h4'} className={landingpage.productTitle}>
                Surface water frequency
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              The surface water frequency data provides information about the
              inundation frequency at any given area of interest
            </Typography>
            <Box my={2}></Box>
            <Typography variant={'body1'}>
              It is a measure of the number of times an area has been observed
              as water covered, within a given period, measured in percentage of
              the total observations.
            </Typography>
            <Box my={2}></Box>
            <Typography variant={'body1'}>
              The product is available at any location, globally, and is
              tailored to specific client needs to provide information about
              inundation frequency for specific months, seasons or years.
            </Typography>
            <Box my={1}>
              <img
                src={imageFloodFrequency}
                alt="flood extent"
                style={{ width: '100%' }}
              />
            </Box>
            <Typography style={{ textAlign: 'right', fontSize: 10 }}>
              Wetness presence over period 2016 – 2019 in Mawlamyine area,
              Myanmar
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default FloodFrequency;
