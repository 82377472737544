import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';

const firstColumn = [
  {
    icon: DotIcon,
    title: 'Disaster response and emergency planning',
    body:
      'Flood maps provide a spatial overview of the extent of flood events, and this information is critical in supporting emergency response efforts, including identification of risk zones, affected communities and vulnerable areas.',
  },
  {
    icon: DotIcon,
    title: 'Public awareness',
    body:
      'Flood maps are an efficient tool for awareness raising efforts to strengthen the ability of communities to respond efficiently to ongoing flood events as well as to protect themselves from future events.',
  },
  {
    icon: DotIcon,
    title: 'Insurance industry',
    body:
      'Flood maps and surface water frequency maps provide critical knowledge which can be used as a parameter to calculate insurance premiums, as well as validate, or substantiate, insurance claims.',
  },
];

const secondColumn = [
  {
    icon: DotIcon,
    title: 'Hydrological modeling',
    body:
      'Flood maps can be used as an integrated parameter to calibrate and validate hydrological models. Knowledge about the actual extent of floods at a given time is, among other things, a critical parameter that can be used to fine-tune flood models to increase the performance and accuracy of flood predictions. Digital elevation models are also an essential parameter in hydrological models.',
  },
  {
    icon: DotIcon,
    title: 'Physical planning',
    body:
      'Knowledge about the extent of current and past flooding’s, as well as the historical frequency, provides guidance which can be used to support spatial planning processes, i.e. through the identification of suitable areas for different types of development. This knowledge can also be included climate adaptation/mitigation plans, municipal development plans and environmental and nature protection plans.',
  },
];

const BulletPoints = ({ padding }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper style={{ flexGrow: 1, overflow: 'hidden' }}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container style={{ padding: padding }}>
          <Grid item xs={12}>
            <Typography
              variant={'h4'}
              className={landingpage.h4}
              style={{ textAlign: 'center', paddingBottom: 32 }}
            >
              Use Cases
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container>
            {firstColumn &&
              firstColumn.map(({ icon: Icon, title, body }, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={2} sm={1}>
                    <Icon className={landingpage.icon} />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography
                      variant={'body1'}
                      style={{ fontWeight: 700 }}
                      gutterBottom
                    >
                      {title}
                    </Typography>
                    <Typography variant={'body1'} paragraph>
                      {body}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
          <Grid item xs={12} sm={6} container>
            {secondColumn &&
              secondColumn.map(({ icon: Icon, title, body }, i) => (
                <React.Fragment key={i}>
                  <Grid item xs={2} sm={1}>
                    <Icon className={landingpage.icon} />
                  </Grid>
                  <Grid item xs={10} sm={11}>
                    <Typography
                      variant={'body1'}
                      style={{ fontWeight: 700 }}
                      paragraph
                    >
                      {title}
                    </Typography>
                    <Typography variant={'body1'} paragraph>
                      {body}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default BulletPoints;
