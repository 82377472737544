import React from 'react';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';

import { HomeContext } from 'Home';
import { makeStyles } from '@material-ui/core';
import { productStyle } from 'styles/global';

const volumeDisclaimer = {
  title: 'Volume discount applies, contact us for pricing',
  description: (
    <Typography variant="body2" paragraph>
      The product selected for your area of interest qualifies for a volume
      discount. Please proceed with your quote request to receive a detailed
      quote and our expert advice on preferred product options for your selected
      area. We will respond to your request within 24 hours
    </Typography>
  ),
};

const volumeDisclaimerStyle = makeStyles({
  link: {
    textAlign: 'right',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const VolumeDisclaimerTotalPrice = () => {
  const {
    actions: { setDialogue },
  } = React.useContext(HomeContext);

  const classes = volumeDisclaimerStyle();
  const style = productStyle();

  const handleClick = e => {
    e.stopPropagation();
    setDialogue(volumeDisclaimer);
  };

  return (
    <Typography
      className={classnames({
        [classes.link]: true,
        [style.regionTitleMain]: true,
      })}
      onClick={handleClick}
    >
      Volume discount applies
    </Typography>
  );
};

export default VolumeDisclaimerTotalPrice;
