import React from 'react';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { productStyle } from 'styles/global';
import { HomeContext } from 'Home';

const defaultDetails = {
  name: null,
  company: null,
  email: null,
  message: null,
};

const ContactDetails = () => {
  const {
    actions: { handleSendMail },
  } = React.useContext(HomeContext);

  const [details, setDetails] = React.useState({ ...defaultDetails });
  const classes = productStyle();

  const updateDetails = e => {
    let obj = details;
    obj[e.target.id] = e.target.value;
    setDetails({ ...obj });
  };

  function validateEmail(email) {
    const regexp = /.+@.+\..+/;
    return regexp.test(email);
  }

  return (
    <Paper>
      <Typography variant="subtitle2" className={classes.regionContainer}>
        Your contact details:
      </Typography>
      <form noValidate autoComplete="off" style={{ padding: 16 }}>
        <TextField
          id="name"
          label="Name"
          style={{ width: '50%' }}
          onChange={e => updateDetails(e)}
        />
        <TextField
          id="company"
          label="Company"
          style={{ width: '50%' }}
          onChange={e => updateDetails(e)}
        />
        <TextField
          id="email"
          label="Email address"
          style={{ width: '100%' }}
          onChange={e => updateDetails(e)}
        />
        <TextField
          id="message"
          label="Message (optional)"
          multiline
          style={{ width: '100%' }}
          onChange={e => updateDetails(e)}
        />
      </form>
      <Paper style={{ padding: 16 }}>
        <Button
          disabled={
            details.name === null ||
            details.company === null ||
            validateEmail(details.email) === false
          }
          style={{ width: '100%' }}
          onClick={() => {
            handleSendMail(details);
            setDetails({ ...defaultDetails });
          }}
        >
          Submit request
        </Button>
      </Paper>
    </Paper>
  );
};

export default ContactDetails;
