import React from 'react';
import DataComponent from './DataComponent';
import Info from './Info';
import Support from './Support';
import Title from './Title/Title';

import { AppContext } from 'App';
import { Paper } from '@material-ui/core';

import 'styles/scrollbar.css';

const Sidebar = () => {
  const {
    state: { sidebarComponent },
  } = React.useContext(AppContext);

  return (
    <Paper
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <Title />
      {sidebarComponent === 0 && <Info />}
      {sidebarComponent === 1 && <DataComponent />}
      {sidebarComponent === 2 && <Support />}
    </Paper>
  );
};

export default Sidebar;
