import React from 'react';
import { format } from 'date-fns';
import { Box, Typography } from '@material-ui/core';
import { AppContext } from 'App';

export default function FloodExtentDetails() {
  const {
    state: { productExtent },
  } = React.useContext(AppContext);

  const { dates, price, priceCap } = productExtent;

  const formattedDates = dates.map(({ date, price, resolution }) => {
    let str = '';
    if (date && date !== null) {
      str += `Date: ${format(date, 'dd MMM yyyy')}, `;
      if (resolution[0].checked) str += `${resolution[0].title}`;
      if (resolution[0].checked && resolution[1].checked) str += ` and `;
      if (resolution[1].checked) str += `${resolution[1].title}`;
      if (price[0] === priceCap) str += ` = Volume discount applies`;
      else if (price[0])
        str += ` = €${price[0].toLocaleString()} - €${price[1].toLocaleString()}`;
    }
    if (resolution[0].checked === false && resolution[1].checked === false)
      str = '';
    return str;
  });

  return (
    <>
      {price && price[0] !== null && price[0] !== 0 && (
        <Box pt={1}>
          <Typography variant="subtitle1">Flood extent:</Typography>
          <Typography variant="body2" style={{ margin: 0 }}>
            {price[0] === priceCap
              ? 'Price: Volume discount applies'
              : 'Price range: €' +
                price[0].toLocaleString() +
                ' - €' +
                price[1].toLocaleString()}
          </Typography>
          {formattedDates &&
            formattedDates.map((date, i) => (
              <Typography key={i} variant="body2" style={{ margin: 0 }}>
                {date}
              </Typography>
            ))}
        </Box>
      )}
    </>
  );
}
